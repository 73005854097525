<template>
  <div class="flex flex-col h-screen mx-auto bg-white md:grid md:grid-cols-2">
    <user-type-selection class="h-screen"/>
    <the-network-graph class="hidden md:block" />
  </div>
</template>

<script>
import TheNetworkGraph from '@/components/TheNetworkGraph.vue';
import UserTypeSelection from '@/components/UserTypeSelection.vue';

export default {
  components: {
    TheNetworkGraph,
    UserTypeSelection
  },
  name: 'LandingPage',
}
</script>

<style scoped>
/* Tailwind CSS styles and custom styles for your landing page */
</style>
