<template>
    <div class="font-inria text-black p-3 rounded shadow-sm mb-3 border-b border-primary w-full bg-white">
        <div class="flex justify-between items-center">
            <div class="flex-grow">
                <label for="title" class="block text-base font-medium text-black">Title</label>
                <input v-model="editableMaterial.title" :readonly="!isEditable" :class="inputClass" class="text-base"
                    placeholder="Material Title" />
            </div>
            <div class="flex align-bottom ml-4">
                <div class="flex">
                    <CustomButton lowPadding hasIcon @button-click="deleteMaterial" class="mr-2">
                        <img :src="getIconPath('trash-2')" class="mx-auto w-5" />
                    </CustomButton >
                    <CustomButton lowPadding hasIcon @button-click="editMaterial" class="mr-2">
                        <img :src="getIconPath(isEditable ? 'save' : 'file-edit')" class="mx-auto w-5" />
                    </CustomButton >
                    <CustomButton lowPadding hasIcon @button-click="viewMaterial" class="mr-2">
                        <img :src="getIconPath('view')" class="mx-auto w-5" />
                    </CustomButton >
                    <CustomButton lowPadding hasIcon @button-click="toggleDetails" class="mr-2">
                        <img :src="getIconPath(isExpanded ? 'chevron-up' : 'chevron-down')" class="mx-auto w-5" />
                    </CustomButton >
                </div>
            </div>
        </div>
        <transition name="fade">
            <div v-show="isExpanded" class="mt-2">
                <label for="author" class="block text-base font-medium text-black">Author</label>
                <input v-model="editableMaterial.author" :readonly="!isEditable" :class="inputClass"
                    placeholder="Material Author" />
                <p v-if="displayFileName" class="text-sm text-gray-600 mt-2">File name: {{ displayFileName }}</p>
                <div v-if="isEditable && !displayFileName">
                    <div class="w-full mt-4">
                        <div class="flex items-center justify-between w-full  mb-2">
                            <div class="flex items-center justify-between w-1/2">
                                <div v-if="selectedFileName" class="text-sm text-gray-600 mt-2 align-middle	">
                                    Selected file: {{ selectedFileName }}
                                </div>
                            </div>
                            <input type="file" @change="handleFileUpload"
                                accept=".c,.cpp,.csv,.docx,.html,.java,.json,.md,.pdf,.php,.pptx,.py,.rb,.tex,.txt" class="hidden" id="file-upload" ref="fileInput" />
                            <div class="flex items-center justify-end w-1/2">
                                <label for="file-upload"
                                    class="cursor-pointer block font-noto bg-primary text-white py-2 px-4 rounded rounded transition duration-300 ease-in-out transform hover:shadow-md hover:scale-95">
                                    Choose File
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-right justify-between">
                        <div class="flex items-right">
                            <h1 v-if="errorMessage" class="text-sm text-primary font-noto text-left mb-4">
                                {{ errorMessage }}
                            </h1>
                        </div>
                    </div>

                </div>
            </div>
        </transition>
    </div>
</template>
  
<script>
import CustomButton  from '../CustomButton.vue';

export default {
    name: 'ReferenceMaterialCard',
    components: {
        CustomButton ,
    },
    props: {
        material: Object,
        isExpanded: Boolean,
        isNew: Boolean,
        isEditable: Boolean
    },
    data() {
        return {
            editableMaterial: this.isNew
                ? { title: '', author: '', isNew: true }
                : { ...this.material, isNew: this.isNew },
            selectedFileName: 'No file chosen',
            errorMessage: '',
        };
    },
    computed: {
        inputClass() {
            return {
                'block w-full rounded-md p-2 border bg-white': true,
                'border-primary': this.isEditable,
                'border-transparent cursor-default': !this.isEditable,
                'focus:outline-none': true,
            };
        },
        displayFileName() {
            if (this.material && this.material.s3_link) {
                return this.material.s3_link.split('/').pop(); // Extract filename from s3_link
            }
            return '';
        },
    },
    methods: {
        toggleDetails() {
            this.$emit('toggle-details', this.material.id);
        },
        selectMaterial() {
            this.$emit('select-card', this.material.id);
        },
        editMaterial() {
            //Print every if to debug
            if (this.isEditable) {
                    // Save logic
                    if (this.isNew && !this.material.isSaved) {
                        console.log("isNew")
                        this.$emit('save-new-material', this.editableMaterial);
                    } else {
                        console.log("else")
                        this.$emit('save-material', this.editableMaterial);
                    }
            } else {
                this.$emit('request-validation', this.editableMaterial, () => {
                    this.$emit('toggle-editable', this.material.id);
                }
                )
            }
        },

        submitNewMaterial() {
            this.$emit('request-validation', this.editableMaterial, () => {
                this.$emit('save-new-material', this.editableMaterial);
                this.$emit('toggle-editable', this.material.id);
            });
        },
        deleteMaterial() {
            this.$emit('delete-material', this.material.id);
        },
        getIconPath(iconName) {
            return require(`@/assets/${iconName}.svg`);
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            const acceptedTypes = ['text/x-c', 'text/x-c++', 'application/csv', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/html', 'text/x-java', 'application/json', 'text/markdown', 'application/pdf', 'text/x-php', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'text/x-python', 'text/x-script.python', 'text/x-ruby', 'text/x-tex', 'text/plain'];
            if (file && acceptedTypes.includes(file.type)) {
                this.selectedFileName = file.name;
                this.editableMaterial.file = file;
            } else {
                this.$toast.error( 'Invalid file type.');
            }
        },
        viewMaterial() {
            if (this.isEditable) {
                this.$toast.error( 'You must save the material before viewing it.');
            } else {
                this.$router.push({ name: 'ReferenceMaterialFile', params: { id: this.material.id }});
            }
        },
    },
};
</script>
  
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
  