<template>
    <div class="flex flex-col items-start space-y-4 text-xs md:w-2/3 w-full mx-auto">
        <h1 class="text-xl text-black font-inria text-center w-full mb-1 mt-3">Final Results</h1>\

        <div v-for="student in students" :key="student.code" class="mb-4 w-full">
            <ApprovedAnswersCard :studentId="student.code" :grade="student.grade" :answers="student.answers" />
        </div>
        <CustomButton v-if="students.length === 0" @button-click="getStudentApprovedAnswers" class="mx-auto mt-8 items-center">
                        Refresh
                    </CustomButton >
    </div>
</template>


<script>
import ApprovedAnswersCard from './ApprovedAnswersCard.vue';
import apiService from '@/services/apiService';
import CustomButton  from '../CustomButton.vue';

export default {
    name: 'ApprovedAnswers',
    components: { ApprovedAnswersCard, CustomButton },
    props: { voucher_id: { type: String, default: null } },
    data() {
        return {
            students: [],
        };
    },
    async created() { await this.getStudentApprovedAnswers(); },
    methods: {
        async getStudentApprovedAnswers() {
            try {
                const response = await apiService.getStudentApprovedAnswers(this.voucher_id);
                if (response.error) {
                    this.$toast.error(response.error);
                } else {
                    this.students = response;
                }
            } catch (error) { this.$toast.error("Incorrect or active voucher provided"); }
        },
    }
};
</script>

<style>
/* Your existing styles */
</style>
