<template>
    <div class="flex flex-col items-start space-y-4 text-xs w-full">
        <h1 class="text-2xl text-black font-inria text-left w-full">
            Welcome to the TurkuEval examination platform
        </h1>
        <form @submit.prevent="onSubmit" class="form-container w-full">
            <div class="flex space-x-5 justify-left w-full mb-3">
                <!-- Other form fields if necessary -->
            </div>
            <LargeSelectionButton :title="'Create new examination instance'"
                :description="'Customize the evaluation experience for your students.'" :subButtons="subButtonData"
                @click="advanceToNextPage" />
        </form>
        <div class="examinations-section w-full">
        <h1 class="text-2xl text-black font-inria text-left w-full mb-6">
            My Examinations
        </h1>
        <ExaminationCard v-for="exam in examinations" :key="exam.id" :examination="exam"
                         @edit-evaluation="editEvaluation" @edit-voucher="editVoucher" @view-results="viewResults" class="w-full"/>
    </div>
    </div>
</template>

<script>
import LargeSelectionButton from '../LargeSelectionButton.vue';
import apiService from '@/services/apiService';
import ExaminationCard from './ExaminationCard.vue';

export default {
    name: 'FirstPage',
    components: {
        LargeSelectionButton,
        ExaminationCard,
    },
    data() {
        return {
            subButtonData: [],
            examinations: [],
            errorMessage: '',
        };
    },
    async created() {
        await this.fetchExaminations();
    },
    methods: {
        advanceToNextPage() {
            this.$router.push({ name: 'EvaluationCriteriaSelection' });
        },
        async fetchExaminations() {
            const response = await apiService.getExaminations();
            if (response.authError || response.error == 'No access token available') {
                    this.$toast.error( 'Session expired. Please log in again.');
                    this.$router.push('/'); // Redirect to login
                } else if (response.error) {
                    this.$toast.error( response.error);
                } else {
                    this.examinations = response;
                }
        },
        onSubmit() {
            // Handle the submit event if necessary
        },
        editEvaluation(examId) {
            this.$router.push({ name: 'EvaluationCriteriaSelection', params: { id: examId } });
        },
        editVoucher(examId) {
            this.$toast.info("Since there are students already registered for this examination, you can't edit the voucher content.");
            this.$router.push({ name: 'VoucherEdition', params: { id: examId } });
        },
        viewResults(voucher_id) {
            this.$router.push({ name: 'StudentAnswer', params: { voucher_id: voucher_id } });
        },
    },
};
</script>
