<template>
  <div
    :class="{ 'font-inria text-black p-3 rounded shadow-sm mb-3 border-b border-primary w-full bg-white': true, 'evaluation-card-selected': isExpanded }">
    <div class="flex justify-between items-center">
      <div v-if="isEditable" class="flex-grow">
        <label for="title" class="block text-base font-medium text-black">Title</label>
        <input v-model="editableCriteria.title" :class="inputClass" class=" w-full" placeholder="Criteria Title" />
      </div>
      <div v-else class="text-base font-semibold flex-grow">
        {{ criteria.title }}
      </div>
      <div class="flex align-bottom ml-4">
        <div class="flex">
          <CustomButton lowPadding hasIcon @button-click="deleteCriteria" class="mr-2">
            <img :src="getIconPath('trash-2')" class="mx-auto w-5" />
          </CustomButton >
          <CustomButton lowPadding hasIcon @button-click="editCriteria" class="mr-2">
            <img :src="getIconPath(isEditable ? 'save' : 'file-edit')" class="mx-auto w-5" />
          </CustomButton >
          <CustomButton lowPadding hasIcon @button-click="toggleDetails" class="mr-2">
            <img :src="getIconPath(isExpanded ? 'chevron-up' : 'chevron-down')" class="mx-auto w-5" />
          </CustomButton >
          <CustomButton lowPadding hasIcon @button-click="selectCriteria" class="mr-2"
            :class="isSelected ? 'bg-primary' : ''">
            <img :src="getIconPath(isSelected ? 'check-circle-2-white' : 'check-circle-2')" class="mx-auto w-5" />
          </CustomButton >
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-show="isExpanded" class="mt-2">
        <div v-if="isEditable">
          <div class="w-full mb-2">
            <label for="description" class="block text-sm font-medium text-black">Description</label>
            <textarea v-model="editableCriteria.description" :class="textareaClass" ref="descriptionTextarea"
                  placeholder="Description" @input="resizeTextarea"></textarea>
          </div>
          <div class="w-full mb-2">
            <label for="impact" class="block text-sm font-medium text-black">Evaluation Scale</label>
            <ul class="text-black">
              <li v-for="(scale, index) in editableCriteria.evaluation_scales" :key="index"
                class="flex items-center py-1 text-xs border-gray-400">
                <input v-model="scale.name" type="text" :class="inputClass" placeholder="Scale name" class="mr-2" />
                <input v-model="scale.scale_lower" type="number" :class="inputClass" placeholder="Lower bound"
                  class="mr-2" />
                <input v-model="scale.scale_upper" type="number" :class="inputClass" placeholder="Upper bound" />
                <CustomButton hasIcon lowPadding @button-click="deleteScale(index)" class="w-10">
                  <img :src="getIconPath('trash-2')" class="mx-auto w-5" />
                </CustomButton >
              </li>
            </ul>
            <CustomButton isBorderedDotted @button-click="addNewScale" class="w-full mt-2 items-center">
              <img :src="getIconPath('plus-circle')" class="mx-auto" />
            </CustomButton >
          </div>
        </div>
        <div v-else>
          <p class="text-black text-sm mb-2">{{ criteria.description }}</p>
          <ul class="text-black">
            <li v-for="scale in criteria.evaluation_scales" :key="scale.id"
              class="flex items-center py-1 text-xs border-b border-dotted border-gray-400">
              <span class="flex-1">{{ scale.name }}:</span>
              <span class="flex-2">{{ scale.scale_lower }} - {{ scale.scale_upper }}</span>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CustomButton  from '../CustomButton.vue';

export default {
  name: 'EvaluationCriteriaCard',
  components: {
    CustomButton ,
  },
  props: {
    criteria: Object,
    isExpanded: Boolean,
    isSelected: Boolean,
    isEditable: {
      type: Boolean,
      default: false,
    },
    isNew: Boolean,
  },
  data() {
    return {
      editableCriteria: this.isNew ? { title: '', description: '', evaluation_scales: [] } : { ...this.criteria },
    };
  },
  computed: {
    inputClass() {
      return {
        'block rounded-xs p-2 border-b': true,
        'border-primary': this.isEditable,
        'border-transparent cursor-default': !this.isEditable,
        'focus:outline-none': true
      };
    },
    textareaClass() {
      return {
        'block w-full rounded-xs resize-none p-2 border-b align-text-bottom': true,
        'border-primary': this.isEditable,
        'border-transparent cursor-default': !this.isEditable,
        'focus:outline-none': true,
      };
    },
    reversedScales() {
    return [...this.editableCriteria.evaluation_scales].reverse();
  }
  },
  methods: {
    resizeTextarea(event) {
      event.target.style.height = 'auto';
      event.target.style.height = (event.target.scrollHeight) + 'px';
  },
    toggleDetails() {
      this.$emit('toggle-details', this.criteria.id);
    },
    selectCriteria() {
      this.$emit('select-card', this.criteria.id);
    },
    editCriteria() {
      if (this.isEditable) {
        // Error if there are one or less scales
        if (this.editableCriteria.evaluation_scales.length <= 1) {
          this.$toast.error('You must have at least two scales');
          return;
        }
        // Handle save logic
        if (this.isNew && !this.criteria.isSaved) {
          this.$emit('save-new-criteria', this.editableCriteria);
        } else {
          this.$emit('save-criteria', this.editableCriteria);
        }
      } else {
        // Handle edit logic
        this.$emit('toggle-editable', this.criteria.id);
      }
    },
    deleteCriteria() {
      if (this.isNew) {
        // Handle removing a "ghost" criterion
        this.$emit('delete-criteria', this.criteria.id);
      } else {
        // Handle deleting an existing criterion
        this.$emit('delete-criteria', this.criteria.id);
      }
    },
    getIconPath(iconName) {
      return require(`@/assets/${iconName}.svg`);
    },
    addNewScale() {
      this.editableCriteria.evaluation_scales.push({ name: '', scale_lower: 0, scale_upper: 0 });
    },
    deleteScale(index) {
      this.editableCriteria.evaluation_scales.splice(index, 1);
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.card-details {
  /* Existing styles... */
  transition: max-height 0.3s ease;
}
.textarea-container textarea {
  min-height: 25px; /* Minimum height */
  overflow-y: hidden; /* Hide vertical scrollbar */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Disable resizing */
}

</style>
