<template>
  <div class="font-inria text-black px-3 py-1 rounded shadow-sm mb-1 border-b border-primary w-full bg-white">
    <div class="flex justify-between items-center mb-1">
      <div class="text-base font-semibold">
        Student ID: <span class="ml-2">{{ studentId }}</span><span class="mx-4">|</span>
        <span class="ml-2 text-xs text-primary">Examination grade: </span><span class="ml-2 text-xs">{{ grade ? grade : "Not graded" }}</span>
        <span class="ml-4 text-xs " :class="{'text-indicator active': validationStatus === 'Validated', 'text-indicator inactive': validationStatus === 'Pending Validation'}">{{ validationStatus }}</span>
      </div>
      <CustomButton hasIcon lowPadding @button-click="toggleDetails" class="mr-2">
        <img :src="getIconPath(isExpanded ? 'chevron-up' : 'chevron-down')" class="mx-auto w-5" />
      </CustomButton>
    </div>
    <transition name="fade">
      <div v-show="isExpanded">
        <div v-for="answer in this.feedbacks" :key="answer.id" class="mb-4">
          <div class="mb-2">
            <p class="text-gray-600 text-sm font-bold">{{ answer.question.text }}</p>
            <p class="text-sm font-bold my-2">Student Answer:</p>
            <p class="text-gray-600">{{ answer.text || "No answer provided" }}</p>
          </div>
          <div v-if="answer.feedback">
            <div class="mb-2">
              <p class="text-sm font-bold">Feedback:</p>
              <textarea v-model="answer.displayedFeedback" :readonly="!editMode[answer.id]"
                        class="block w-full rounded-xs p-2 focus:outline-none h-16"
                        :class="{ 'border-b border-primary ': editMode[answer.id] }"></textarea>
              <div v-for="(value, criterion) in answer.displayedMarks" :key="criterion">
                <label class="block text-sm font-bold text-black inline">{{ criterion }}</label>
                <select v-model="answer.displayedMarks[criterion]" :disabled="!editMode[answer.id]"
                        class="block w-32 rounded-xs px-2 py-1 my-1 focus:outline-none inline"
                        :class="{ 'border-b border-primary': editMode[answer.id], 'border-none': !editMode[answer.id], 'bg-white': editMode[answer.id], 'bg-gray-100': !editMode[answer.id] }">
                  <option value="Fail">Fail</option>
                  <option value="Passable">Passable</option>
                  <option value="Satisfactory">Satisfactory</option>
                  <option value="Good">Good</option>
                  <option value="Very Good">Very Good</option>
                  <option value="Excellent">Excellent</option>
                </select>
              </div>
              <p class="text-sm font-bold">Final Mark: {{
                ((answer.feedback.final_mark_teacher ? answer.feedback.final_mark_teacher : answer.feedback.final_mark_ai) * answer.question.weight).toFixed(2) }} / {{ answer.question.weight*100 }}
              </p>
            </div>
            <div class="flex justify-center font-inria text-black w-full">
              <CustomButton hasIcon hasBlackText lowPadding @button-click="toggleEditMode(answer.id)" class="mr-2">
                <img :src="getIconPath(editMode[answer.id] ? 'save' : 'file-edit')" class="mx-auto w-5 inline mr-2" />
                {{ editMode[answer.id] ? 'Save Feedback' : 'Edit Feedback' }}
              </CustomButton>
              <CustomButton hasIcon lowPadding hasBlackText
                            @button-click="isFeedbackApproved(answer) ? null : approveFeedback(answer)"
                            :class="['mr-2', isFeedbackApproved(answer) ? 'bg-green-500' : '']">
                <img :src="getIconPath(isFeedbackApproved(answer) ? 'badge-check-approved' : 'badge-check')"
                     class="mx-auto w-5 inline mr-2" />
                {{ isFeedbackApproved(answer) ? 'Approved' : 'Approve Feedback' }}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import CustomButton from '../CustomButton.vue';

export default {
  name: 'StudentAnswerCard',
  components: { CustomButton },
  props: { studentId: String, answers: Array, grade: String },
  setup(props, { emit }) {
    const isExpanded = ref(false);
    const editMode = ref({});
    const feedbacks = computed(() => props.answers.map(answer => ({
      ...answer,
      displayedFeedback: answer.feedback ? answer.feedback.feedback_teacher || answer.feedback.feedback_ai : "",
      displayedMarks: answer.feedback ? answer.feedback.marks_teacher || answer.feedback.marks_ai : {},
    })));
    const validationStatus = computed(() => {
      // Check if any feedbacks are missing validation
      return feedbacks.value.some(feedback => !isFeedbackApproved(feedback)) ? 'Pending Validation' : 'Validated';
    });
    const toggleDetails = () => isExpanded.value = !isExpanded.value;
    const toggleEditMode = (answerId) => {
      const answerIndex = props.answers.findIndex(a => a.id === answerId);
      if (answerIndex !== -1) {
        const answer = feedbacks.value[answerIndex];
        if (editMode.value[answerId]) {
          emit('save-feedback', {
            answerId: answer.id,
            feedbackId: answer.feedback.id,
            feedback: answer.displayedFeedback,
            marks: answer.displayedMarks
          });
        }
        editMode.value[answerId] = !editMode.value[answerId];
      }
    };
    const approveFeedback = (answer) => {
      if (!isFeedbackApproved(answer)) {
        answer.feedback.feedback_teacher = answer.feedback.feedback_ai;
        answer.feedback.marks_teacher = answer.feedback.marks_ai;
        emit('save-feedback', {
            answerId: answer.id,
            feedbackId: answer.feedback.id,
          feedback: answer.feedback.feedback_ai,
          marks: answer.feedback.marks_ai
        });
      }
    };
    const isFeedbackApproved = (answer) => answer.feedback && answer.feedback.feedback_teacher && Object.keys(answer.feedback.marks_teacher).length > 0;
    return { isExpanded, editMode, feedbacks, toggleDetails, toggleEditMode, approveFeedback, isFeedbackApproved, validationStatus };
  },
  methods: { getIconPath: (iconName) => require(`@/assets/${iconName}.svg`) }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active { transition: opacity 0.3s ease; }
.fade-enter, .fade-leave-to { opacity: 0; }
textarea { resize: vertical; }
textarea.resize-none { resize: none; }
.text-indicator.active {
  color: #4CAF50; /* Green for active */
}

.text-indicator.inactive {
  color: #F44336; /* Red for inactive */
}
</style>
