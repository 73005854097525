<template>
  <div class="flex flex-col items-start space-y-4 text-xs w-full">
      <h1 class="text-xl text-black font-inria text-left w-full mb-4">
          Select a reference material or create a new one
      </h1>
      <div v-if="referenceMaterials.length === 0" class="text-primary text-sm">
          No reference materials to show
      </div>
      <ReferenceMaterialCard v-for="material in referenceMaterials" :key="material.id" :material="material"
          :isExpanded="expandedMaterial === material.id" 
          :isEditable="material.isEditable" :isNew="material.isNew" @toggle-details="toggleDetails"
          @delete-material="deleteMaterial" @save-material="saveMaterial"
          @save-new-material="saveNewMaterial" @toggle-editable="toggleEditable"
          @request-validation="handleValidationRequest" />

      <CustomButton isBorderedDotted @button-click="createNewMaterial" class="w-full items-center">
          <img :src="getIconPath('plus-circle')" class="mx-auto" />
      </CustomButton >
  </div>
</template>

<script>
import ReferenceMaterialCard from './ReferenceMaterialCard.vue';
import CustomButton from '../CustomButton.vue';
import apiService from '@/services/apiService';
import { PDFDocument } from 'pdf-lib';

const icons = require.context('@/assets', false, /\.svg$/);

export default {
    name: 'ReferenceMaterialOverview',
    components: {
        ReferenceMaterialCard,
        CustomButton
    },
    data() {
        return {
            errorMessage: '',
            referenceMaterials: [],
            expandedMaterial: null,
            examination: null,
            selectedMaterials: [],
            isSaving: false,
        };
    },
    async created() {
        await this.fetchReferenceMaterials();
    },
    methods: {
        getIconPath(iconName) {
            return icons(`./${iconName}.svg`);
        },
        toggleDetails(id) {
            this.expandedMaterial = this.expandedMaterial === id ? null : id;
        },
        selectMaterial(id) {
            const index = this.selectedMaterials.indexOf(id);
            if (index > -1) {
                this.selectedMaterials.splice(index, 1);
            } else if (this.selectedMaterials.length < 4) {
                this.selectedMaterials.push(id);
            } else {
                this.$toast.error('You can select up to 4 materials.');
            }
        },
        async fetchReferenceMaterials() {
            try {
                const response = await apiService.fetchReferenceMaterials();
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/'); // Redirect to login
                } else if (response.error) {
                    // Handle other errors
                    this.$toast.error(response.error);
                } else {
                    console.log(response)
                    this.referenceMaterials = response.map(material => ({
                        ...material,
                        displayCardName: material.s3_link ? material.s3_link.split('/').pop() : '',
                        isEditable: false,
                        isNew: false,
                        isExpanded: false,
                        isSaved: true
                    }));
                }
            } catch (error) {
                this.$toast.error('Failed to fetch reference materials.');
            }
        },

        deleteNewMaterial(id) {
            this.referenceMaterials = this.referenceMaterials.filter(material => material.id !== id);
            this.expandedMaterial = null;
            this.selectedMaterial = null;
        },

        async deleteMaterial(id) {
            if (id === 0 || this.referenceMaterials.find(m => m.id === id && m.isNew)) {
                this.deleteNewMaterial(id);
            } else {
                try {
                    const response = await apiService.deleteReferenceMaterial(id);
                    if (response.authError || response.error == 'No access token available') {
                        this.$toast.error('Session expired. Please log in again.');
                        this.$router.push('/'); // Redirect to login
                    } else if (response.error) {
                        this.$toast.error(response.error);
                    } else {
                        this.referenceMaterials = await apiService.fetchReferenceMaterials();
                    }
                } catch (error) {
                    this.$toast.error('An error occurred while deleting the material.');
                }
            }
        },
        async saveMaterial(material) {
            if (this.validateMaterial(material, material.isNew)) {
                if (material.isNew && !material.isSaved) {
                    await this.saveNewMaterial(material);
                } else {
                    await this.updateMaterial(material);
                }
            }
        },
        toggleEditable(id) {
            const index = this.referenceMaterials.findIndex(m => m.id === id);
            if (index !== -1) {
                this.referenceMaterials[index].isEditable = !this.referenceMaterials[index].isEditable;
            }
        },
        showSavingMessage() {
            this.$toast.error("Cant perform actions while uploading", {
                duration: null
            });
        },
        createNewMaterial() {
            this.referenceMaterials.push({
                id: Date.now(), // Temporary ID for new material
                title: '',
                author: '',
                file: null,
                isEditable: true,
                isExpanded: true,
                isNew: true,
                isSaved: false
            });
            this.expandedMaterial = this.referenceMaterials[this.referenceMaterials.length - 1].id;
        },
        handleValidationRequest(material, callback) {
            console.log(material)
            if (this.validateMaterial(material, material.isNew)) {
                if (typeof callback === 'function') {
                    callback();
                }
            }
        },
        async saveNewMaterial(material) {
            let isMaterialCorrect = await this.validateMaterial(material,material.isNew);
            if (!isMaterialCorrect) return;
            this.isSaving = true;
            let instance = this.$toast.default("Uploading material...", {
                duration: null
            });
            try {
                const response = await apiService.uploadReferenceMaterial(material);
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/'); // Redirect to login
                } else if (response.error) {
                    this.$toast.error(response.error);
                } else {
                    const index = this.referenceMaterials.findIndex(m => m.id === material.id);
                    this.$toast.clear(instance);
                    this.$toast.success("Material uploaded successfully!")
                    this.referenceMaterials.splice(index, 1, {
                        ...response,
                        isEditable: false,
                        isNew: false,
                        isSaved: true,
                        isExpanded: false
                    });
                this.toggleEditable(material.id);
                }
            } catch (error) {
                this.$toast.error('Failed to save new material.');
            }
            this.isSaving = false;
        },
        async getPageCount(file) {
            // Use a CardReader to read the file's contents
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    try {
                        // Load the PDF document
                        const pdfDoc = await PDFDocument.load(new Uint8Array(e.target.result));
                        // Resolve the promise with the page count
                        resolve(pdfDoc.getPageCount());
                    } catch (error) {
                        // If there's an error, reject the promise
                        reject(error);
                    }
                };
                reader.onerror = () => {
                    // Handle errors that occur during the read operation
                    reject(reader.error);
                };
                // Read the file as an ArrayBuffer
                reader.readAsArrayBuffer(file);
            });
        },
        async updateMaterial(material) {
            try {
                const response = await apiService.updateReferenceMaterial(material.id, material);
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/'); // Redirect to login
                } else if (response.error) {
                    this.$toast.error(response.error);
                } else {
                    const index = this.referenceMaterials.findIndex(m => m.id === material.id);
                    this.referenceMaterials.splice(index, 1, {
                        ...response,
                        isEditable: false,
                        isNew: false,
                        isSaved: true,
                        isExpanded: false
                    });
                }
            } catch (error) {
                this.$toast.error('Failed to update material.');
            }
        },
        async validateMaterial(material, isNewMaterial = false) {
            if (!material.title || !material.author) {
                this.$toast.error('Please fill in the title and author.');
                return false;
            }
            if (isNewMaterial && !material.file) {
                this.$toast.error('Please select a file for the new material.');
                return false;
            }
            if (isNewMaterial && material.file) {
                if (!material.file.name.endsWith('.pdf')) {
                    this.$toast.error('Only PDF files are allowed.');
                    return false;
                }
                try {
                    // Await the page count from the getPageCount method
                    const pageCount = await this.getPageCount(material.file);
                    console.log("PAGE COUNT IS")
                    console.log(pageCount)
                    if (pageCount > 40) {
                        this.$toast.error('The file must not exceed 40 pages.');
                        return false;
                    }
                } catch (error) {
                    // Handle the error appropriately
                    this.$toast.error('Could not read the PDF file.');
                    return false;
                }
            }
            return true;
        },
        async addReferenceMaterials() {
            if (this.selectedMaterials && this.selectedMaterials.length > 0 && this.selectedMaterials.length <= 3) {
                if (this.examination.id) {
                    const response = await apiService.addReferenceMaterialsToExamination(this.examination.id, this.selectedMaterials);
                    if (response.authError || response.error == 'No access token available') {
                        this.$toast.error('Session expired. Please log in again.');
                        this.$router.push('/');
                    } else if (response.error) {
                        // Handle other errors
                        this.$toast.error(response.error);
                    } else {
                        this.$router.push({ name: 'QuestionSelection', params: { id: this.examination.id } });
                    }
                } else {
                    this.$toast.error('There was an error adding the materials to the evaluation criteria.');
                }
            } else {
                this.$toast.error('Please select between 1 and 3 materials.');
            }
        },
        async getExaminationData(examinationId) {
            try {
                const response = await apiService.getExamination(examinationId);
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/');
                } else if (response.error) {
                    // Handle other errors
                    this.$toast.error(response.error);
                } else {
                    this.examination = response;
                    this.selectedMaterials = response.reference_materials.map(material => material.id);
                }
            } catch (error) {
                this.$toast.error('Failed to fetch examination data.');
            }
        },
    },
};
</script>