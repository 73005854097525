<template>
    <div class="flex flex-col w-full bg-white min-h-screen">
        <h1 v-if="examinationName" class="text-2xl text-black font-inria w-full mb-4 text-center mt-6">
            {{ examinationName }}
        </h1>
        <div v-if="!timerEnded"
            class="text-xl font-inria w-full mb-4 text-center mt-6 text-black items-middle md:flex md:justify-center">
            <div v-if="hasLimitedTime"><span class="text-xs text-primary ">Time Remaining </span> <span class="mx-6">|
                </span> {{ hours }}h {{
                    minutes
                }}m {{ seconds }}s<span v-if="hasLimitedTime" class="mx-8 hidden md:inline-block">| </span></div>
            <div> <span class="text-xs text-primary">Your Student Code </span>
                <span class="mx-6">| </span> {{ studentCode }}
            </div>
        </div>
        <div v-if="examinationData" class="md:grid gap-4" :class="splitOrNot">
            <!-- Reference Materials Component -->
            <div v-if="examinationData.access_material_during_test || isMobile" class="md:mb-0 w-full mx-auto px-4 py-2"
                v-show="showMaterialSection || !isMobile">
                <student-reference-materials :mode="'after'"></student-reference-materials>
            </div>
            <!-- Examination Questions Component -->
            <div class="w-full mx-auto p-4" v-show="showQuestionsSection || !isMobile">
                <examination-questions ref="examinationQuestions" :questions="examinationData.questions"
                    :answers="examinationData.answers"></examination-questions>
            </div>
        </div>
        <div v-if="examinationData" class="w-full mb-4 text-center mt-6">
            <div>
                <CustomButton @click="endExaminationToast()" isBordered lowPadding class="h-12 mt-2 text-s mr-2">
                    Submit Responses 
                </CustomButton>
            </div>
        </div>          
        <div v-else-if="isLoading" class="text-center">Loading...</div>
        <div v-else class="text-center">Examination data not available</div>
        <!-- Toggle for mobile view -->
        <div v-if="isMobile" class="flex justify-center mt-2 space-x-4">
            <CustomButton class="text-sm" :class="selectedPart == 'material' ? 'text-white bg-primary' : ''" isBordered
                hasBlackText hasIcon @click="toggleView('material')">Materials</CustomButton>
            <CustomButton class="text-sm" :class="selectedPart == 'questions' ? 'text-white bg-primary' : ''" isBordered
                hasBlackText hasIcon @click="toggleView('questions')">Questions</CustomButton>
        </div>
    </div>
</template>
  
<script>
import apiService from '@/services/apiService';
import StudentReferenceMaterials from '@/components/StudentExamination/StudentReferenceMaterials.vue';
import ExaminationQuestions from '@/components/StudentExamination/ExaminationQuestions.vue';
import CustomButton from '../CustomButton.vue';

export default {
    name: 'StudentExamination',
    components: {
        StudentReferenceMaterials,
        ExaminationQuestions,
        CustomButton
    },
    data() {
        return {
            selectedPart: 'material',
            examinationData: null,
            isLoading: true,
            showMaterialSection: true,
            showQuestionsSection: false,
            examinationName: "",
            remainingTime: 0, // Set initial value to 0
            timerEnded: false,
            hours: 0,
            minutes: 0,
            seconds: 0,
            timerInterval: null,
            checkInterval: null,
            studentCode: "",
            hasLimitedTime: false
        };
    },
    created() {
        if (localStorage.getItem('feedbackGiven')) {
            this.$router.push('/student-examinations/feedback');
        }
        this.fetchExaminationData();
        this.studentCode = localStorage.getItem('studentCode');
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        },
        splitOrNot() {
            return (this.examinationData && this.examinationData.access_material_during_test && !this.isMobile) ? 'grid-cols-2' : 'grid-cols-1';
        }
    },
    methods: {
        async fetchExaminationData() {
            const voucherId = localStorage.getItem('voucher');
            const uuid = localStorage.getItem('uuid');
            if (voucherId && uuid) {
                try {
                    const response = await apiService.getExaminationContent(voucherId, uuid);
                    this.examinationData = response;
                    this.hasLimitedTime = response.duration;
                    this.examinationName = response.name;
                    this.remainingTime = response.remaining_time;
                    console.log(response)
                    // Start the countdown timer only after fetching the data
                    if (this.hasLimitedTime) {
                        if (this.remainingTime) {
                            this.timerInterval = setInterval(this.updateTimer, 1000);
                        }
                        else {
                            this.$toast.info("Your examination time has ended.");
                            await this.endExamination();
                        }
                    }

                } catch (error) {
                    this.$toast.error("Error loading examination data");
                } finally {
                    this.isLoading = false;
                }
            } else {
                this.$toast.error("Data not found");
                this.isLoading = false;
            }
        },
        toggleView(section) {
            this.selectedPart = section;
            if (section === 'material') {
                this.showMaterialSection = true;
                this.showQuestionsSection = false;
            } else {
                this.showMaterialSection = false;
                this.showQuestionsSection = true;
            }
        },
        handleResize() {
            this.$forceUpdate();
        },
        async updateTimer() {
            if (this.remainingTime > 0) {
                this.hours = Math.floor(this.remainingTime / 3600);
                this.minutes = Math.floor((this.remainingTime % 3600) / 60);
                this.seconds = this.remainingTime % 60;
                this.remainingTime--;
            } else if (this.remainingTime > -10) {
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
                this.remainingTime--;
            }
            else {
                this.timerEnded = true;
                this.$toast.info("Your examination time has ended.");
                await this.endExamination();
            }
        },
        async endExamination() {
            let isAnswerSent = true;
            if (this.$refs.examinationQuestions) {
                isAnswerSent = await this.$refs.examinationQuestions.sendAnswer();
            }

            clearInterval(this.timerInterval);
            clearInterval(this.checkInterval); 
            this.timerInterval = null;

            if (isAnswerSent) {
                this.$router.push('/student-examinations/feedback');
            } else {
                this.$toast.error('Failed to send answer. Please try again.');
            }
        },
        async sendCurrentAnswer() {
            await this.$refs.examinationQuestions.sendAnswer();
        },
        getIconPath(iconName) {
            return require(`@/assets/${iconName}.svg`);
        },
        endExaminationToast() {
            this.$toast.open({
                message: 'Click here to finish the examination',
                type: 'info',
                duration: 5000,
                onClick: () => {
                    this.endExamination();
                }
            });
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        // Set up the interval to check conditions every ten seconds
        this.checkInterval = setInterval(this.sendCurrentAnswer, 30000);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
        if (this.checkInterval) {
            clearInterval(this.checkInterval); // Clear the new check interval
        }
    },
};
</script>
  