<template>
  <div class="bg-white rounded shadow-sm mb-4 border-b border-primary">
    <div class="px-4 py-2 rounded-t-lg text-black">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <h2 class="text-lg text-black">{{ examination.name }}</h2>
          <p v-if="examination.vouchers && examination.vouchers.length > 0 && examination.vouchers[0].active" 
             class="align-baseline inline-block ml-6 status-indicator active py-1 px-2 rounded text-white">Active</p>
          <p v-else-if="examination.vouchers && examination.vouchers.length > 0 && !examination.vouchers[0].active" 
             class="align-baseline inline-block ml-6 status-indicator inactive py-1 px-2 rounded text-white">Inactive</p>
          <p v-if="isExaminationIncomplete" 
             class="align-baseline inline-block ml-6 status-indicator incomplete py-1 px-2 rounded text-white">Incomplete</p>
        </div>
        <div class="flex">
          <CustomButton lowPadding hasIcon @button-click="editEvaluationCriteria"  class="mr-2">
            <img :src="getIconPath('file-edit')" class="mx-auto w-5" />
          </CustomButton >
          <CustomButton v-if="!isExaminationIncomplete" lowPadding hasIcon @button-click="viewEvaluationResults"  class="mr-2">
            <img :src="getIconPath('book-open-check')" class="mx-auto w-5" />
          </CustomButton >
          <CustomButton lowPadding hasIcon @button-click="toggleDetails">
            <img :src="getIconPath(isExpanded ? 'chevron-up' : 'chevron-down')" class="mx-auto w-5" />
          </CustomButton >
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-show="isExpanded" class="px-4 pb-4">
        <div class="details space-y-2 text-black">
          <div v-if="examination.vouchers && examination.vouchers.length > 0"><strong>Voucher:</strong> <span class="text-primary text-xl font-inria">{{ examination.vouchers[0].id }}</span></div>
          <div v-if="examination.vouchers && examination.vouchers.length > 0"><strong>Registered Students:</strong> <span >{{ examination.vouchers[0].registered_students }}</span></div>
          <div><strong>Date:</strong> {{ examination.date }}</div>
          <div><strong>Duration:</strong> {{ durationFromSecondsToHsMs }} </div>
          <div><strong>Knowledge Level:</strong> {{ examination.knowledge_level }}</div>

          <div class="mt-4">
            <h3 class="text-black"><strong>Evaluation Criteria:</strong></h3>
            <p>{{ examination.evaluation_criteria.title }}</p>
          </div>

          <div class="mt-4">
            <h3 class="text-black"><strong>Evaluation Parameters:</strong></h3>
            <ul class="list-disc pl-5">
              <li v-for="param in examination.evaluation_parameters" :key="param.id">
                <strong>{{ param.name }}:</strong> {{ param.description }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CustomButton  from '../CustomButton.vue';

export default {
  name: 'ExaminationCard',
  components: {
    CustomButton ,
  },
  props: {
    examination: Object,
  },
  computed: {
  isExaminationIncomplete() {
    return !this.examination.name ||
           !this.examination.date ||
           !this.examination.knowledge_level ||
           !this.examination.evaluation_criteria ||
           this.examination.evaluation_parameters.length === 0 ||
           this.examination.vouchers.length === 0;
  },
  durationFromSecondsToHsMs() {
    return  `${Math.floor(this.examination.duration / 3600)}h ${Math.floor((this.examination.duration % 3600) / 60)}m`;
  }
},
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleDetails() {
      this.isExpanded = !this.isExpanded;
    },
    editEvaluationCriteria() {
      if (this.examination.vouchers && this.examination.vouchers.length > 0 && this.examination.vouchers[0].registered_students != 0){
        this.$emit('edit-voucher', this.examination.id);
      }
      else{
        this.$emit('edit-evaluation', this.examination.id);
      }
    },
    getIconPath(iconName) {
      return require(`@/assets/${iconName}.svg`);
    },
    viewEvaluationResults() {
      this.$emit('view-results', this.examination.vouchers[0].id);
    }
  },
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}


.status-indicator.active {
  background-color: #4CAF50; /* Green for active */
}

.status-indicator.inactive {
  background-color: #F44336; /* Red for inactive */
}

.status-indicator.incomplete {
  background-color: #808080; /* Gray for incomplete */
}
</style>