<template>
  <div class="relative overflow-hidden">
    <div class="rotate-graph absolute inset-0 bg-network-pattern bg-cover w-100"></div>
  </div>
</template>

<script>
export default {
  name: 'TheNetworkGraph',
  data() {
    return {
      rotating: true,
    };
  },
  methods: {
    toggleRotation() {
      this.rotating = !this.rotating;
      const graphElement = this.$el.querySelector('.rotate-graph');
      if (this.rotating) {
        graphElement.style.animationPlayState = 'running';
      } else {
        graphElement.style.animationPlayState = 'paused';
      }
    },
  },
  // Other script parts...
}
</script>

<style scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-graph {
  animation: rotate 120s linear infinite;
  background-image: url('@/assets/mainpagedecorator.svg');
  width: 50vw; /* Adjust viewport width percentage as needed */
  height: 100vh; /* Adjust viewport height percentage as needed */
  background-size: 100% 100%; 
}

/* Additional styles... */
</style>