import { createApp } from 'vue'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import App from './App.vue'
import router from './router';
import './assets/styles/tailwind.css';

const app = createApp(App);
app.use(router);
app.use(ToastPlugin, {
    position: 'top'
})
app.mount('#app');