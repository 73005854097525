<template>
  <div class="font-inria text-black px-3 py-1 rounded shadow-sm mb-1 border-b border-primary w-full bg-white">
    <div class="flex justify-between items-center mb-1">
      <div class="text-base font-semibold">
        Student ID: <span class="ml-2">{{ studentId }}</span><span class="mx-4">|</span>
        <span class="ml-2 text-xs text-primary">Examination grade: </span><span class="ml-2 text-xs">{{ grade ? grade : "Not graded" }}</span>
      </div>
      <button @click="toggleDetails" class="mr-2">
        <img :src="getIconPath(isExpanded ? 'chevron-up' : 'chevron-down')" class="mx-auto w-5" />
      </button>
    </div>
    <transition name="fade">
      <div v-show="isExpanded">
        <div v-for="answer in this.feedbacks" :key="answer.id" class="mb-4">
          <div class="mb-2">
            <p class="text-gray-600 text-sm font-bold">{{ answer.question.text }}</p>
            <p class="text-sm font-bold my-2">Student Answer:</p>
            <p class="text-gray-600">{{ answer.text || "No answer provided" }}</p>
          </div>
          <div v-if="answer.feedback">
            <div class="mb-2">
              <p class="text-sm font-bold">Feedback:</p>
              <p class="text-gray-600">{{ answer.feedback.feedback_teacher }}</p>
              <div v-for="(value, criterion) in answer.feedback.marks_teacher" :key="criterion">
                <label class="block text-sm font-bold text-black inline">{{ criterion }}: </label>
                <p class="text-gray-600 inline">{{ value }}</p>
              </div>
              <p class="text-sm font-bold">Final Mark: {{
                (answer.feedback.final_mark_teacher * answer.question.weight).toFixed(2) }} / {{ answer.question.weight*100 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { ref, computed } from 'vue';

export default {
  name: 'StudentAnswerCard',
  props: { studentId: String, answers: Array, grade: String },
  setup(props) {
    const isExpanded = ref(false);
    const feedbacks = computed(() => props.answers.map(answer => ({
      ...answer,
      displayedFeedback: answer.feedback ? answer.feedback.feedback_teacher : "",
      displayedMarks: answer.feedback ? answer.feedback.marks_teacher : {},
    })));
    const validationStatus = computed(() => {
      return feedbacks.value.some(feedback => !feedback.feedback_teacher) ? 'Pending Validation' : 'Validated';
    });
    const toggleDetails = () => isExpanded.value = !isExpanded.value;
    const getIconPath = (iconName) => require(`@/assets/${iconName}.svg`);
    return { isExpanded, feedbacks, toggleDetails, validationStatus, getIconPath };
  }
};
</script>
