<template>
  <div class="flex flex-col items-center justify-center w-full p-4 text-black">
    <div class="text-xl font-semibold mb-6">Question {{ currentQuestionIndex + 1 }}</div>

    <!-- Question Text and Answer Area -->
    <div class="flex flex-col items-center w-full max-w-lg mb-4">
      <div class="text-lg mb-3 question-area">{{ localQuestions[currentQuestionIndex].text }}</div>
      <textarea v-model="localQuestions[currentQuestionIndex].answerText" rows="6"
        class="w-full p-2 border border-primary-light rounded focus:border-primary outline-none mt-2"
        style="resize: both; max-width: 80vw;" placeholder="Type your answer here..." maxlength="1500"></textarea>
      <div class="text-xs text-primary w-full text-align-right">
        {{ characterCount }} / 1500
      </div>
    </div>

    <!-- Navigation Buttons -->
    <div class="flex items-center justify-center gap-3 mb-4">
      <!-- Previous Button -->
      <CustomButton hasIcon lowPadding @click="changeQuestion(-1)" :disabled="currentQuestionIndex === 0"
        class="w-10 mx-2">
        <img :src="getIconPath('chevron-left')" class="mx-auto w-8" />
      </CustomButton>

      <!-- Question Indicator Buttons -->
      <button v-for="(question, index) in localQuestions" :key="index" @click="changeQuestionTo(index)"
        :class="['rounded-full', currentQuestionIndex === index ? 'bg-primary h-2 w-2 ' : 'bg-primary h-1 w-1 ']"></button>

      <!-- Next Button -->
      <CustomButton hasIcon lowPadding @click="changeQuestion(1)"
        :disabled="currentQuestionIndex === localQuestions.length - 1" class="w-10 mx-2">
        <img :src="getIconPath('chevron-right')" class="mx-auto w-8" />
      </CustomButton>
    </div>
  </div>
</template>


<script>
import CustomButton from '@/components/CustomButton.vue';
import apiService from '@/services/apiService';


export default {
  name: 'ExaminationQuestions',
  props: {
    questions: Array,
    answers: Array // Add answers as a prop
  },
  components: {
    CustomButton,
  },
  data() {
    return {
      localQuestions: this.initializeQuestionsWithAnswers(), // Initialize with answers
      currentQuestionIndex: 0,
    };
  },
  computed: {
    characterCount() {
    // Ensure that answerText is a string to avoid null or undefined errors.
    const answerText = this.localQuestions[this.currentQuestionIndex].answerText || '';
    return answerText.length;
  },
  },
  methods: {
    initializeQuestionsWithAnswers() {
      return this.questions.map(question => {
        const answerObj = this.answers.find(answer => answer.question_id === question.id);
        if (!answerObj) {
          console.warn(`No answer found for question ID: ${question.id}`);
        }
        return {
          ...question,
          answerText: answerObj ? answerObj.text : '',
          answerId: answerObj ? answerObj.id : null,
        };
      });
    },


    changeQuestionTo(index) {
      if (this.currentQuestionIndex !== index) {
        this.sendCurrentAnswer(index);
        this.currentQuestionIndex = index;
      }
    },
    changeQuestion(direction) {
      const newIndex = this.currentQuestionIndex + direction;
      if (newIndex >= 0 && newIndex < this.localQuestions.length) {
        this.sendCurrentAnswer(newIndex);
        this.currentQuestionIndex = newIndex;
      }
    },
    async sendAnswer() {
        console.log("sendAnswer called with currentQuestionIndex:", this.currentQuestionIndex);
        if (this.currentQuestionIndex !== null) {
            return this.sendCurrentAnswer(this.currentQuestionIndex);
        }
        return true;
    },
    async sendCurrentAnswer(newIndex) {
        const currentQuestion = this.localQuestions[this.currentQuestionIndex];
        const changedToQuestion = this.localQuestions[newIndex];

        console.log("Sending current answer:", currentQuestion);
        console.log("Switching to question:", changedToQuestion);

        try {
            const studentUuid = localStorage.getItem('uuid');
            const payload = {
                new_answer_id: changedToQuestion.answerId,
                current_answer_text: currentQuestion.answerText || '',
                voucher: localStorage.getItem('voucher'),
            };
            const response = await apiService.switchStudentAnswer(studentUuid, payload);
            console.log("Answer saved successfully:", response);
            return true;
        } catch (error) {
            console.error("Error updating answer:", error);
            return false;
        }
    },
    getIconPath(iconName) {
      return require(`@/assets/${iconName}.svg`);
    },
    sendAnswerAndEmitEvent() {
      this.sendAnswer(); // This is your existing method to send the answer
      this.$emit('answer-sent'); // Emitting a custom event
    },
  },

};
</script>


<style scoped>
.question-area {
  min-height: 160px;
  /* Adjust as needed */
}
</style>
