<template>
    <div>
        <div class="space-y-4 font-inria text-black">
            <h1 class="text-2xl text-black font-inria text-left w-full mb-4">
                Select a question or create a new one
            </h1>
            <LargeSelectionButton :title="'Generate AI assisted questions'"
                :description="'It will generate 5 questions with the provided references and guidelines'"
                @click="generateQuestions" class="w-full" :disabled="isGenerating"
                :class="{ 'bg-primary-dark': isGenerating }" />
            <QuestionCard v-for="(question, index) in questions" :key="index" :question="question" :questionIndex="index"
                @update-question="updateQuestionCard" @delete-question="deleteQuestion" />

            <CustomButton isBorderedDotted @button-click="addQuestion" class="w-full items-center">
                <img :src="getIconPath('plus-circle')" class="mx-auto" />
            </CustomButton>
            <div class="flex items-right justify-between w-full">
                <div class="flex items-right">
                    <h1 v-if="errorMessage" class="text-sm text-primary font-inria text-left mb-4">
                        {{ errorMessage }}
                    </h1>
                </div>
                <div>
                    <CustomButton @button-click="updateExaminationQuestions">Continue</CustomButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton from '../CustomButton.vue';
import QuestionCard from './QuestionCard.vue';
import apiService from '@/services/apiService';
import LargeSelectionButton from '../LargeSelectionButton.vue';

export default {
    name: 'QuestionSelection',
    components: {
        QuestionCard,
        CustomButton,
        LargeSelectionButton
    },
    data() {
        return {
            questions: [],
            examination: null,
            errorMessage: '',
            isGenerating: false,
        };
    },
    async created() {
        this.examination = this.$route.params.id || null;
        await this.fetchQuestions();
    },
    methods: {
        async fetchQuestions() {
            try {
                const response = await apiService.fetchQuestions(this.examination);
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/');
                } else if (response.error) {
                    this.$toast.error(response.error);
                } else {
                    this.questions = response.map((question) => ({
                        ...question,
                        editableInitially: false,
                        isNew: false,
                    }));
                }
            } catch (error) {
                this.$toast.error('Error fetching questions.');
            }
        },
        async generateQuestions() {
            if (this.isGenerating) return;
            this.isGenerating = true;
            let instance = this.$toast.default("Generating questions, please don't close the page...", {
                duration: null
            });

            try {
                const response = await apiService.generateAIQuestions(this.examination);
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.clear(instance);
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/');
                } else if (response.error) {
                    this.$toast.clear(instance);
                    this.$toast.error(response.error);
                } else {
                    this.$toast.clear(instance);
                    this.$toast.success('Questions generated successfully.');
                    this.questions = this.questions.concat(response.map((question) => ({
                        ...question,
                        editableInitially: false,
                        isNew: true,
                    })));
                }
            } catch (error) {
                this.$toast.error('Error generating questions.');
                this.isGenerating = false;
            }
            this.isGenerating = false;
        },
        addQuestion() {
            if (this.isGenerating){
                this.$toast.error('You can not add questions while generating them');
                return;
            }   
            if (this.questions.length >= 5) {
                this.$toast.error( 'You can only add up to 5 questions');
                return;
            }
            this.questions.push({ text: '', weight: 0, editableInitially: true, isNew: true, isExpanded: false, examination_id: parseInt(this.examination) });
        },
        deleteQuestion(index) {
            this.questions.splice(index, 1);
        },
        updateQuestionCard(updatedQuestion, index) {
            this.questions.splice(index, 1, updatedQuestion);
        },
        getIconPath(iconName) {
            return require(`@/assets/${iconName}.svg`);
        },
        async updateExaminationQuestions() {
            if (this.validateQuestions()) {
                try {
                    const response = await apiService.updateExaminationQuestions(this.examination, this.questions);
                    if (response.authError || response.error == 'No access token available') {
                        this.$toast.error('Session expired. Please log in again.');
                        this.$router.push('/');
                    } else if (response.error) {
                        this.$toast.error(response.error);
                    } else {
                        await this.getVoucherByExaminationId(this.examination);
                    }
                } catch (error) {
                    this.$toast.error('Error updating examination questions.');
                }
            }
        },
        validateQuestions() {
            if (this.isGenerating) {
                this.$toast.error('Please wait until the questions are generated');
                return false;
            }
            if (this.questions.length === 0) {
                this.$toast.error('Please add at least one question.');
                return false;
            }
            else if (this.questions.length > 5) {
                this.$toast.error('You can only add up to 5 questions.');
                return false;
            }
            for (const question of this.questions) {
                if (!question.text || question.weight == null) {
                    this.$toast.error('Please fill in the text and weight of all questions.');
                    return false;
                }
            }
            const totalWeight = this.questions.reduce((total, question) => total + question.weight, 0);
            const roundedTotalWeight = parseFloat(totalWeight.toFixed(2)); // Round to 2 decimal places
            if (roundedTotalWeight !== 1) {
                this.$toast.error('The sum of the weights must be 100%');
                return false;
            }
            return true;
        },

        async getVoucherByExaminationId(examinationId) {
            try {
                const response = await apiService.getExaminationVoucher(examinationId);
                if (!response) {
                    await this.generateVoucher(examinationId);
                } else if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/');
                } else if (response && response.error === 'Voucher not found') {
                    await this.generateVoucher(examinationId);
                } else if (response.error) {
                    this.$toast.error(response.error);
                } else {
                    this.$router.push({ name: 'VoucherEdition', params: { id: this.examination } });
                }
            } catch (error) {
                this.$toast.error('Error getting voucher by examination id.');
            }
        },
        async generateVoucher(examinationId) {
            try {
                const response = await apiService.generateVoucher(examinationId);
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/');
                } else if (response.error) {
                    this.$toast.error(response.error);
                } else {
                    this.$router.push({ name: 'VoucherEdition', params: { id: this.examination } });
                }
            } catch (error) {
                this.$toast.error('Error generating voucher.');
            }
        },
    },
};
</script>
