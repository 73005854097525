<template>
  <div class="flex flex-col bg-white md:min-h-screen">

  <div :class="this.mode == 'before' ? 'w-full p-2 md:mx-auto md:w-2/3' : 'w-full p-2'">
    <h1 v-if="this.mode == 'before'" class="text-2xl text-black font-inria mb-4 text-center mt-10 w-full">
      Reference Material Review
    </h1>

    <div v-if="hasMultipleMaterials" class="mb-4 font-inria text-black flex items-center justify-center">
      Select a material to review: 
      <select v-model="selectedMaterial" @change="updateMaterialSelection"
        class="block w-1/2 border border-primary rounded shadow-sm ml-4 p-2 bg-white">
        <option v-for="material in materials" :key="material.id" :value="material">
          {{ material.title }}
        </option>
      </select>
    </div>
    <div class="bg-white rounded shadow-sm border-b border-primary w-full" v-if="selectedMaterial">
      <div class="px-4 py-2 rounded-t-lg text-black w-full">
        <div class="flex justify-between items-center w-full">
          <h2 v-if="!hasMultipleMaterials" class="text-xl font-semibold">{{ selectedMaterial.title }}</h2>
          <CustomButton v-if="this.mode == 'before'" lowPadding hasIcon @button-click="goToFiles">
            <img :src="getIconPath('arrow-left-circle')" class="mx-auto w-5" />
          </CustomButton>
        </div>
      </div>
      <vue-pdf-app v-if="this.mode == 'before'" style="height: 80vh;" :config="config" :pdf="selectedMaterial.s3_link"></vue-pdf-app>
      <vue-pdf-app v-if="this.mode != 'before'" style="height: 60vh;" :config="config" :pdf="selectedMaterial.s3_link"></vue-pdf-app>
    </div>

    <div v-else class="text-center">No reference materials available.</div>
  </div></div>
</template>
<script>
import CustomButton from '../CustomButton.vue';
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
import apiService from '../../services/apiService';

export default {
  name: 'StudentReferenceMaterials',
  components: {
    CustomButton,
    VuePdfApp
  },
  props: {
    mode: {
      type: String,
      default: 'before',
    },
  },
  data() {
    return {
      materials: [],
      selectedMaterial: null,
      config: {
        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
            download: false,
            pageNumber: false,
          },
          toolbarViewerRight: false,
          toolbarViewerMiddle: {
            scaleSelectContainer: false,
          }
        },
        secondaryToolbar: false
      },
      intervalId: null, // Store the interval ID so we can clear it later
    };
  },
  mounted() {
    // Set up the interval to check every ten seconds
    this.intervalId = setInterval(() => {
      this.checkAndNavigate();
    }, 10000);
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  computed: {
    hasMultipleMaterials() {
      return this.materials && this.materials.length > 1;
    }
  },
  methods: {
    checkAndNavigate() {
      const uuid = localStorage.getItem('uuid');
      if (this.mode === 'before' && uuid) {
        this.$toast.error('You already started the exam');
        this.$router.push({ name: 'StudentExamination' });
      }
    },
    updateMaterialSelection() {
      // Only update if there are multiple materials
      if (this.hasMultipleMaterials && this.selectedMaterial === null) {
        this.selectedMaterial = this.materials[0]; // Default to the first material
      }
    },
    goToFiles() {
      if (this.mode == "before"){
        this.$router.push({ name: 'StudentLanding' });
      }
    },
    getIconPath(iconName) {
      return require(`@/assets/${iconName}.svg`);
    },
    async fillMaterials() {
      const voucherId = localStorage.getItem('voucher');
      try {
        const response = await apiService.getPublicExaminationInformation(voucherId);
        this.materials = response.reference_materials;
        if (this.materials.length === 1) {
          this.selectedMaterial = this.materials[0]; // Automatically select if only one material
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    }
  },
  async created() {
        if (localStorage.getItem('feedbackGiven')){
            this.$router.push('/student-examinations/feedback');
        }
    await this.fillMaterials();
    if (this.mode == 'before' && localStorage.getItem('uuid')) {
      this.$toast.error('You already started the exam');
      this.$router.push({ name: 'StudentExamination' });
    }
    this.updateMaterialSelection(); // Initialize the selected material
  }
};
</script>
