<template>
    <div class="flex flex-col items-start space-y-4 text-xs w-full">
        <h1 class="text-xl text-black font-inria text-left w-full mb-1">Evaluate Student Answers</h1>

        <!-- Add buttons for sorting -->
        <div class="mb-4 justify-end flex w-full">
            <CustomButton class="mr-2" @click="sortStudentsByMarks">Sort by Marks</CustomButton>
            <CustomButton @click="sortStudentsById">Sort by ID</CustomButton>
        </div>

        <div v-for="student in students" :key="student.code" class="mb-4 w-full">
            <StudentAnswerCard :studentId="student.code" :grade="student.grade" :answers="student.answers"
                @save-feedback="saveFeedback" />
        </div>
    </div>
</template>


<script>
import StudentAnswerCard from './StudentAnswerCard.vue';
import apiService from '@/services/apiService';
import CustomButton from '../CustomButton.vue';

export default {
    name: 'AnswersSelection',
    components: { StudentAnswerCard, CustomButton },
    props: { voucher_id: { type: String, default: null } },
    data() {
        return {
            students: [],
            sortMarksDescending: true,
            sortIdDescending: true,
        };
    },
    async created() { await this.getStudentAnswers(); },
    methods: {
        async getStudentAnswers() {
            try {
                const response = await apiService.getStudentAnswers(this.voucher_id);
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/');
                } else if (response.error) {
                    this.$toast.error(response.error);
                } else {
                    this.students = response;
                }
            } catch (error) { this.$toast.error('Error fetching student answers.'); }
        },
        sortStudentsByMarks() {
            this.sortMarksDescending = !this.sortMarksDescending;
            this.students = this.students.slice().sort((a, b) => {
                // Calculate total marks for student 'a'
                const totalMarksA = a.answers.reduce((sum, ans) => sum + (Number(ans.feedback?.final_mark_teacher) || Number(ans.feedback?.final_mark_ai) || 0), 0);
                // Calculate total marks for student 'b'
                const totalMarksB = b.answers.reduce((sum, ans) => sum + (Number(ans.feedback?.final_mark_teacher) || Number(ans.feedback?.final_mark_ai) || 0), 0);

                // Check if either student doesn't have grades yet
                const aHasNoGrades = a.answers.every(ans => !ans.feedback);
                const bHasNoGrades = b.answers.every(ans => !ans.feedback);

                if (aHasNoGrades && bHasNoGrades) return 0; // Both have no grades, retain existing order
                if (aHasNoGrades) return 1; // 'a' has no grades, place it after 'b'
                if (bHasNoGrades) return -1; // 'b' has no grades, place it after 'a'

                // Sort by total marks in descending order
                if (totalMarksA > totalMarksB) return this.sortMarksDescending ? -1 : 1;
                if (totalMarksA < totalMarksB) return this.sortMarksDescending ? 1 : -1;
                return 0;
            });
        },
        sortStudentsById() {
            this.sortIdsDescending = !this.sortIdsDescending; // Toggle the sorting order for ID

            this.students = this.students.slice().sort((a, b) => {
                if (this.sortIdsDescending) {
                    return a.code > b.code ? -1 : 1;
                } else {
                    return a.code < b.code ? -1 : 1;
                }
            });
        },
        async saveFeedback(feedbackData) {
            try {
                const response = await apiService.updateFeedback(feedbackData.feedbackId, {
                    feedback_teacher: feedbackData.feedback,
                    marks_teacher: feedbackData.marks
                });
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/');
                } else if (response.error) {
                    this.$toast.error(response.error);
                } else {
                    this.$toast.success('Feedback saved successfully.');
                    await this.getStudentAnswers();
                    this.updateStudentFeedback(feedbackData.answerId, feedbackData.feedback, feedbackData.marks, response.final_mark_teacher);
                }
            } catch (error) { this.$toast.error('Error saving feedback.'); }
        },
        updateStudentFeedback(answerId, feedback, marks, finalMarkTeacher) {
            this.students = this.students.map(student => ({
                ...student,
                answers: student.answers.map(answer => {
                    if (answer.id === answerId) {
                        return { ...answer, feedback: { ...answer.feedback, feedback_teacher: feedback, marks_teacher: marks, final_mark_teacher: finalMarkTeacher } };
                    }
                    return answer;
                })
            }));
        }
    }
};
</script>

<style>
/* Your existing styles */
</style>
