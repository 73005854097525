import axios from 'axios';

function handleApiError(error) {
  if (error.response) {
    if (error.response.status === 401) {
      localStorage.removeItem('access_token');
      return { authError: true };
    } else if (error.response.status === 400) {
      return { error: error.response.data.detail || 'Bad Request' };
    }
  } else {
    console.error('API Error:', error);
    return { error: error.message || 'Unknown error occurred' };
  }
}

function handleLoginError(error) {
if (error.response) {
    if (error.response.status === 401) {
      return { authError: true };
    } else if (error.response.status === 400) {
      return { error: error.response.data.detail || 'Bad Request' };
    }
  }
}

export default {
  async login(username, password) {
    const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
    try {
      const response = await axios.post(`${apiBaseUrl}/login/`, { username, password });
      if (response.data && response.data.access_token) {
        localStorage.setItem('access_token', response.data.access_token);
      }
      return response.data;
    } catch (error) {
      return handleLoginError(error); 
    }
  },
  async createTeacher(username, password, secret_key){
    const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
    try {
      const response = await axios.post(`${apiBaseUrl}/teachers/`, { username, password, secret_key });
      return response.data;
    }
    catch (error) {
      return handleApiError(error);
    }
  },
  async getVoucherById(id) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/vouchers/${id}`);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async postVoucher(voucherData) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/students/`, {"voucher": voucherData}, {
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async fetchEvaluationCriteria() {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/evaluation-criteria/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async hideEvaluationCriteria(id) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) throw new Error("No access token available");

      const updateData = { show: false };
      const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/evaluation-criteria/${id}`, updateData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async createEvaluationCriteria(criteria) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) throw new Error("No access token available");

      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/evaluation-criteria`, criteria, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async updateEvaluationCriteria(id, criteria) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) throw new Error("No access token available");

      const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/evaluation-criteria/${id}`, criteria, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async createExamination(examination) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) throw new Error("No access token available");

      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/examinations`, examination, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async updateExamination(id, examination) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) throw new Error("No access token available");
      //remove date from examination
      delete examination.date;
      console.log('examination')  
      console.log(examination)
      const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/examinations/${id}`, examination, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async fetchReferenceMaterials() {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/reference-materials`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async getReferenceMaterial(id) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/reference-materials/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  async uploadReferenceMaterial(materialData) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const formData = new FormData();
      formData.append('title', materialData.title);
      formData.append('author', materialData.author);
      if (materialData.file) {
        formData.append('file', materialData.file);
      }
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/reference-materials`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  async updateReferenceMaterial(id, materialData) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) throw new Error("No access token available");

      const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/reference-materials/${id}`, materialData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async deleteReferenceMaterial(id) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) throw new Error("No access token available");

      const response = await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/reference-materials/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data; // or return true to indicate success
    } catch (error) {
      return handleApiError(error);
    }
  },
  async getStudentAnswers(voucher_id){
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      // pass voucher id as extra params, like limit, offset, etc /students-approved-feedbacks/
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/students?voucher_id=${voucher_id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async getStudentApprovedAnswers(voucher_id){
    try {
      // pass voucher id as extra params, like limit, offset, etc /students-approved-feedbacks/
      const toy = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/students-approved-feedbacks/?voucher_id=${voucher_id}`, {
      });
      console.log(toy.data)
      return toy.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
    
  
  async getExaminations() {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/examinations`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async getExamination(examinationId) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/examinations/${examinationId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async addReferenceMaterialsToExamination(examinationId, referenceMaterialIds) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const payload = {
        reference_material_ids: referenceMaterialIds
      }
      const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/examinations/${examinationId}/update-reference-materials`, payload, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async fetchQuestions(examinationId){
try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/examinations/${examinationId}/questions`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    }
    catch (error) {
      return handleApiError(error);
    }
  },
  async generateAIQuestions(examinationId){
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/examinations/${examinationId}/generate_questions`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    }
    catch (error) {
      return handleApiError(error);
    }
  },
  async updateExaminationQuestions(examinationId, questions){
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/examinations/${examinationId}/questions`, questions, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    }
    catch (error) {
      return handleApiError(error);
    }
  },
  async getExaminationVoucher(examinationId){
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/vouchers/examination/${examinationId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    }
    catch (error) {
      return handleApiError(error);
    }
  },
  async generateVoucher(examinationId){
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/vouchers`, {examination_id: examinationId}, {  
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    }
    catch (error) {
      return handleApiError(error);
    }
  },
  async toggleVoucherStatus(voucherId){
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error("No access token available");
      }
      const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/vouchers/${voucherId}/toggle-status`, {}, {  
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    }
    catch (error) {
      return handleApiError(error);
    }
  },
  async getPublicExaminationInformation(voucherId){
    try {
      // get without headers
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/examinations/${voucherId}/examination_characteristics/`);
      return response.data;
    }
    catch (error) {
      return handleApiError(error);
    }
  },
  async getExaminationContent(voucherId, studentUUID){
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/examinations/${voucherId}/examination_content/`, {
        headers: { 'Student-UUID': studentUUID }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async switchStudentAnswer(student_uuid, payload) {
    try {
      const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/student-switch-answer`, payload, {
        headers: { 'Student-UUID': student_uuid }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async endExamination(student_uuid) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/students-end-examination/${student_uuid}`, {}, {});
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  async submitFeedback(student_uuid, feedbackData){
    try {
      // Add uuid to feedbackData
      feedbackData.student_uuid = student_uuid;
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/student_experience_feedbacks/`, feedbackData, {
      });
      return response.data;
    }
    catch (error) {
      return handleApiError(error);
    }
  },
  async updateFeedback(feedbackId, feedbackData) {
    try {
        const token = localStorage.getItem('access_token');
        if (!token) throw new Error("No access token available");

        const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/feedbacks/${feedbackId}`, feedbackData, {
            headers: { Authorization: `Bearer ${token}` },
        });

        return response.data;
    } catch (error) {
        return handleApiError(error);
    }
}


};
