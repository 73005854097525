<template>
    <div class="flex flex-col items-start space-y-4 text-black text-base w-full">
        <div class="font-inria mx-auto ">
            <h2 class="text-2xl font-inria text-left  mb-6">Voucher Management</h2>
            <!-- Voucher Code Display, for each character of the id, show an underline -->
            <div class="mx-auto mt-8">
                <label class="block text-sm font-medium text-gray-700 mb-4">Voucher Code</label>
                <div class="mt-1 mx-auto rounded-md shadow-sm p-2">
                    <span v-for="char in voucher.id" :key="char" class="mx-4 border-b border-primary text-4xl font-inria">{{
                        char }}</span>
                </div>
            </div>

            <div class="flex items-right justify-between mt-10">
                <div class="flex items-center">
                    <span class="mr-2">Status:</span>
                    <span :class="{ 'status-active': voucher.active, 'status-inactive': !voucher.active }">
                        {{ voucher.active ? 'Active' : 'Inactive' }}
                    </span>
                </div>
                <div>
                    <CustomButton  :type="voucher.active ? 'button' : 'button'" @click="toggleVoucherStatus" class="text-xs ml-2"
                        :class="{ 'deactivate-button': voucher.active, 'activate-button': !voucher.active }">
                        {{ voucher.active ? 'Deactivate (and end exam)' : 'Activate' }} Voucher
                    </CustomButton >
                </div>
            </div>
            <CustomButton hasBlackText hasIcon isBordered @click="goToExaminations" class="mt-10 w-full font-medium	">
                Go to my examinations
            </CustomButton >
        </div>
    </div>
</template>
  
<script>
import CustomButton  from '../CustomButton.vue';
import apiService from '@/services/apiService';

export default {
    name: 'VoucherManagement',
    components: {
        CustomButton ,
    },
    data() {
        return {
            voucher: {},
            examinationId: '',
        };
    },
    created() {
        this.examinationId = this.$route.params.id;
        this.getVoucher();
    },
    methods: {
        async toggleVoucherStatus() {
            let instance = this.$toast.default("Closing examination for all students and making AI feedback, this could take a while...", {
                duration: null
            });
            const response = await apiService.toggleVoucherStatus(this.voucher.id);
            if (response.authError || response.error == 'No access token available') {
                this.$toast.error('Session expired. Please log in again.');
                this.$router.push('/'); 
            } else if (response.error) {
                // Handle other errors
                this.$toast.error(response.error);
            } else {
                this.$toast.success('Voucher status updated successfully.');
                this.getVoucher();
            }
            this.$toast.clear(instance);
        },
        async getVoucher() {
            const response = await apiService.getExaminationVoucher(this.examinationId);
            if (response.authError || response.error == 'No access token available') {
                this.$toast.error('Session expired. Please log in again.');
                this.$router.push('/'); 
            } else if (response.error) {
                // Handle other errors
                this.$toast.error(response.error);
            } else {
                this.voucher = response;
            }
        },
        goToExaminations() {
            this.$router.push('/examinations');
        },
    },
};
</script>
  
<style>
.voucher-code {
    font-size: 24px;
    /* Large font size */
    letter-spacing: 3px;
    /* Space between letters */
    text-transform: uppercase;
    /* Uppercase letters */
}

.status-active {
    color: #4CAF50;
    /* Green color for active status */
}

.status-inactive {
    color: #F44336;
    /* Red color for inactive status */
}

.activate-button {
    background-color: #4CAF50;
    /* Green background for activate button */
}

.deactivate-button {
    background-color: #F44336;
    /* Red background for deactivate button */
}
</style>
  