<template>
    <div class="flex flex-col w-full px-4 pt-4 text-black">
        <h2 class="text-2xl font-inria text-center text-primary mb-2">Examination Results</h2>
        <div class="mt-2 font-inria justify-between mx-auto">
            <span class="text-base text-primary">Student ID</span> <span class="mx-8">|</span>
            <span class="text-xl">{{ this.studentID }}</span>
        </div>
        <div class="mb-4 font-inria justify-between mx-auto">
            <span class="text-base text-primary">Grade</span> <span class="mx-8">|</span>
            <span class="text-xl">{{ markConverted }}</span>
        </div>

        <div class="mx-auto mt-4">
            <div v-for="questionData in questionsData" :key="questionData.question_id" class="mb-8 pb-8 border-b border-dashed border-primary">
                <div class=" mb-2"><span class='text-primary font-semibold text-base'>Question: </span>
                {{ questionData.question_text
                }}</div>
                <div class="mb-2">
                    <span class="font-semibold">Your Answer: </span>
                    <span v-if="questionData.student_answer">{{ questionData.student_answer }}</span>
                    <span v-else class="text-gray-dark">No answer</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold">Feedback:</span> {{ questionData.feedback_ai }}
                </div>
                <div class="mb-2">
                    <ul>
                        <li v-for="(value, key) in questionData.marks_ai" :key="key"><span class="font-semibold">{{ key }}</span>: {{ value }}</li>
                    </ul>
                </div>
                <div>
                    <span class="font-semibold">Question Score:</span> {{ questionData.question_mark }} / {{ questionData.question_weight*100 }}
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'FeedbackCard',
    props: {
        questionsData: Array,
        totalMark: Number,
        markConverted: String,
    },
    data(){
        return {
            studentID: "",
        }
    },
    created(){
        this.studentID = localStorage.getItem("studentCode");
    }
};
</script>
  
<style scoped></style>
  