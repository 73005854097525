<template>
    <div class="p-4 border-b border-primary rounded mb-4 bg-white">
        <div class="flex justify-between items-start">
            <textarea v-model="localQuestion.text" :readonly="!isEditable" :class="inputClass" placeholder="Question Text"
                class="w-4/5 no-resize bg-white" rows="2"></textarea>
            <div class="flex">
                <CustomButton lowPadding hasIcon @button-click="deleteQuestion" class="mr-2 align-top">
                    <img :src="getIconPath('trash-2')" class="mx-auto w-5" />
                </CustomButton >
                <CustomButton lowPadding hasIcon @button-click="isEditable ? saveChanges() : toggleEdit()"
                    class="mr-2 align-top">
                    <img :src="getIconPath(isEditable ? 'save' : 'file-edit')" class="mx-auto w-5" />
                </CustomButton >
            </div>
        </div>

        <div class="w-full flex mt-2 items-center">
        <label for="weight" class="block text-black my-1 w-20 pl-4">Weight: </label>
        <input type="range" v-model="sliderValue" :disabled="!isEditable"
               min="0" max="1" step="0.05" class="w-full slider" id="weightSlider">
        <span class="ml-2">{{ displayWeight }}%</span>
    </div>
    </div>
</template>

<script>
import CustomButton  from '../CustomButton.vue';

const icons = require.context('@/assets', false, /\.svg$/);

export default {
    name: 'QuestionCard',
    components: {
        CustomButton ,
    },
    props: {
        question: Object,
        questionIndex: Number,
    },
    created() {
        this.isEditable = this.question.editableInitially
    },
    data() {
        return {
            localQuestion: { ...this.question },
            isEditable: false,
            sliderValue: this.question.weight, // Initialize slider value
        };
    },
    computed: {
        inputClass() {
            return {
                'block rounded-md p-1 border-b text-sm ': true,
                'border-primary': this.isEditable,
                'border-transparent cursor-default': !this.isEditable,
                'focus:outline-none': true,
            };
        },
        displayWeight() {
            return (this.sliderValue * 100).toFixed(0); // Display formatted weight
        },
    },
    methods: {
        adjustTextareaHeight(event) {
            event.target.style.height = 'auto';
            event.target.style.height = event.target.scrollHeight + 'px';
        },
        toggleEdit() {
            this.isEditable = true;
        },
        saveChanges() {
            if (this.validateQuestion()) {
                this.isEditable = false;
                this.$emit('update-question', {...this.localQuestion, weight: parseFloat(this.sliderValue)}, this.questionIndex);
            }
        },
        deleteQuestion() {
            this.$emit('delete-question', this.questionIndex);
        },
        getIconPath(iconName) {
            return icons(`./${iconName}.svg`);
        },
        validateQuestion(){
            if (this.localQuestion.text === '') {
                this.$toast.error('Question text cannot be empty');
                return false;
            }
            if (this.localQuestion.weight <= 0 || this.localQuestion.weight > 1) {
                this.$toast.error('Question weight must be more than 0 and less than 100');
                return false;
            }
            return true
        }
    },
    watch: {
        sliderValue(newValue) {
            if (this.isEditable) {
                this.localQuestion.weight = parseFloat(newValue); // Ensure it's a number
            }
        },
        question: {
            deep: true,
            handler(newVal) {
                this.localQuestion = { ...newVal };
                this.sliderValue = newVal.weight; // Update slider value
            }
        },
    },
};
</script>
<style>
.no-resize {
    resize: none;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;   
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background: #9B4D4D;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #9B4D4D;
    cursor: pointer;
}
</style>