// src/App.vue

<template>
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Inria+Serif:wght@400;700&family=Noto+Sans:wght@400;500;700&family=Playfair+Display:wght@400;500;700&family=Raleway:wght@400;500;600;700&display=swap" rel="stylesheet">
  <div id="app" class="font-inria text-white min-h-screen h-full min-w-screen w-full ">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
}
</script>

<style>
#app{
  background-color: #f1f1f1;
}
.v-toast__item {
    font-size: 13px;
    font-weight: normal;
    font-family: 'Noto Sans', sans-serif;
    border-radius: 0.5rem;
}
.v-toast__item--error{
    background-color: #C04040;
}
.v-toast__item--success{
    background-color: #75b575;
}

@media (min-width: 768px) {
  .v-toast__item {
        margin-left: 256px;
    }
}
</style>
