<template>
  <div 
    class="p-4 bg-primary font-inria rounded-lg shadow-lg cursor-pointer transition duration-300 ease-in-out hover:bg-primary-dark"
    @click="componentClicked"
    :class="{'hover:shadow-md': !subButtons.length}"
  >
    <h3 class="text-lg text-white font-semibold mb-2 transition duration-300">{{ title }}</h3>
    <p class="text-white transition duration-300">{{ description }}</p>
    <div class="flex flex-wrap mt-3 space-x-2" v-if="subButtons.length">
      <button
        v-for="(subButton, index) in subButtons"
        :key="index"
        class="py-2 px-4 bg-secondary rounded text-white transition duration-300 ease-in-out hover:bg-secondary-dark"
        @button-click="subButtonClicked(subButton)"
      >
        {{ subButton.text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LargeSelectionButton',
  props: {
    title: String,
    description: String,
    subButtons: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    subButtonClicked(subButton) {
      this.$emit('sub-button-click', subButton);
    },
    componentClicked() {
      if (!this.subButtons.length) {
        this.$emit('component-click');
      }
    },
  },
};
</script>