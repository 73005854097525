<template>
  <div class="container flex flex-col md:flex-row items-center md:items-start mx-auto py-4">
    <educator-main-menu class="mb-4 md:mb-0 md:mr-4" />
    <div class="content-wrapper flex flex-col items-center w-full">
      <div class="content w-full mx-auto p-4 rounded text-sm">
        <router-view class=""></router-view> <!-- This will render child components -->
      </div>
    </div>
  </div>
</template>

<script>
import EducatorMainMenu from '@/components/EducatorMainMenu.vue';
// import EducatorMainSelection from '@/components/EducatorMainSelection.vue';

export default {
  components: {
    EducatorMainMenu,
    // EducatorMainSelection,
  }
}
</script>

<style scoped>
.container {
    max-width: 1000px;
    min-width: 320px;
    width: 100%;
}

@media (min-width: 768px) {
    .container {
        padding-left: 256px;
    }
}
</style>