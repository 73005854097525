import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/views/LandingPage.vue';
import EducatorCreate from '@/components/EducatorCreate.vue';
import EducatorMainPage from '@/views/EducatorMainPage.vue';
import MainPage from '@/components/ExaminationCreation/MainPage.vue';
import EvaluationCriteriaSelection from '@/components/ExaminationCreation/EvaluationCriteriaSelection.vue';
import CreateExamination from '@/components/ExaminationCreation/CreateExamination.vue';
import ReferenceMaterialSelection from '@/components/ExaminationCreation/ReferenceMaterialSelection.vue';
import QuestionSelection from '@/components/ExaminationCreation/QuestionSelection.vue';
import VoucherEdition from '@/components/ExaminationCreation/VoucherEdition.vue';
import StudentAnswer from '@/components/ExaminationCreation/StudentAnswer.vue';
import ApprovedAnswers from '@/components/ExaminationCreation/ApprovedAnswers.vue'
import ReferenceMaterialOverview from '@/components/ReferenceMaterial/ReferenceMaterialOverview.vue';
import ReferenceMaterialFile from '@/components/ReferenceMaterial/ReferenceMaterialFile.vue';
import StudentMainPage from '@/views/StudentMainPage.vue';
import StudentLanding from '@/components/StudentExamination/StudentLanding.vue';
import StudentExamination from '@/components/StudentExamination/StudentExamination.vue';
import StudentReferenceMaterials from '@/components/StudentExamination/StudentReferenceMaterials.vue';
import FeedbackVisualization from '@/components/StudentExamination/FeedbackVisualization.vue';
import ResetEvaluation from '@/components/StudentExamination/ResetEvaluation.vue';
import StudentExperienceFeedbackForm from '@/components/StudentExamination/StudentExperienceFeedbackForm.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage,
  },
  {
    path: '/educator-creation',
    name: 'EducatorCreate',
    component: EducatorCreate,
  },
  {
    path: '/results/:voucher_id?',
    name: 'ApprovedAnswers',
    component: ApprovedAnswers,
    props: true
  },
  {
    path: '/examinations',
    component: EducatorMainPage,
    children: [
      {
        path: '',
        name: 'MainPage',
        component: MainPage,
      },
      {
        path: 'evaluation-criteria/:id?',
        name: 'EvaluationCriteriaSelection',
        component: EvaluationCriteriaSelection,
        props: true,
      },
      {
        path: 'create-examination/:criteriaId?/:examinationId?',
        name: 'CreateExamination',
        component: CreateExamination,
        props: true,
      },
      {
        path: 'reference-materials/:id?',
        name: 'ReferenceMaterialSelection',
        component: ReferenceMaterialSelection,
        props: true,
      },
      {
        path: 'questions/:id?',
        name: 'QuestionSelection',
        component: QuestionSelection,
        props: true,
      },
      {
        path: 'voucher/:id?',
        name: 'VoucherEdition',
        component: VoucherEdition,
        props: true,
      },
      {
        path: 'reference-material-overview',
        name: 'ReferenceMaterialOverview',
        component: ReferenceMaterialOverview,
      },
      {
        path: 'reference-material-file/:id?',
        name: 'ReferenceMaterialFile',
        component: ReferenceMaterialFile,
        props: true,
      },
      {
        path: 'student-answer/:voucher_id?',
        name: 'StudentAnswer',
        component: StudentAnswer,
        props: true,
      }
    ]

  },
  {
    path: '/student-examinations',
    component: StudentMainPage,
    children: [
      {
        path: '',
        name: 'StudentLanding',
        component: StudentLanding,
      },
      {
        path: 'examination/',
        name: 'StudentExamination',
        component: StudentExamination
      },
      {
        path: 'reference-materials/:mode',
        name: 'StudentReferenceMaterials',
        component: StudentReferenceMaterials,
        props: true 
      },
      {
        path: 'feedback',
        name: 'FeedbackVisualization',
        component: FeedbackVisualization
      },
      {
        path: 'reset-evaluation',
        name: 'ResetEvaluation',
        component: ResetEvaluation
      },
      {
        path: 'student-experience-feedback-form',
        name: 'StudentExperienceFeedbackForm',
        component: StudentExperienceFeedbackForm
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
