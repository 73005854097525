<template>
    <div class=" flex md:w-1/2 mx-auto py-4 rounded text-sm">
        <form @submit.prevent="onSubmit" class="flex flex-col space-y-4 w-30 items-center text-xs mx-auto">
            <div class="flex space-x-5 justify-left w-full mb-3">
                <input class="w-10 py-3 px-2 text-center rounded text-black bg-white border border-primary"
                    v-for="(item, index) in voucher" :key="index" type="text" maxlength="1" v-model="voucher[index]"
                    ref="inputFields" @input="focusNext(index, $event)" @keydown.backspace="backspaceHandler(index, $event)"
                    @keydown.left="focusPrevious(index)" @keydown.right="focusNext(index, $event)" />
            </div>
            <CustomButton type="submit" class=" w-full">Submit</CustomButton>
            <CustomButton isLinkStyle @button-click="goBack">Back</CustomButton>
        </form>
    </div>
</template>

  
<script>
import CustomButton from './CustomButton.vue';
import apiService from '@/services/apiService'; // Adjust path as needed

export default {
    name: 'StudentVoucherForm',
    components: {
        CustomButton,
    },
    data() {
        return {
            voucher: Array(5).fill(''), // Assuming the voucher has 5 characters
        };
    },
    methods: {
        focusNext(index, event) {
            if (event.target.value.length === 1 && index < this.voucher.length - 1) {
                this.$refs.inputFields[index + 1].focus();
            }
        },
        focusPrevious(index) {
            if (index > 0) {
                this.$refs.inputFields[index - 1].focus();
            }
        },
        backspaceHandler(index, event) {
            if (event.target.value.length === 0 && index > 0) {
                this.voucher[index - 1] = '';
                this.$refs.inputFields[index - 1].focus();
            }
        },
        async onSubmit() {
            try {
                const voucherCode = this.voucher.join('').toUpperCase();
                const response = await apiService.getVoucherById(voucherCode);
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Invalid voucher.');
                    this.$router.push('/');
                } else if (response.error) {
                    this.$toast.error(response.error);
                } else {
                    // save voucher to localstorage
                    localStorage.setItem('voucher', voucherCode);
                    this.$router.push('/student-examinations');
                    // localStorage.setItem('student_uuid', response.data.uuid);
                }
            } catch (error) {
                this.$toast.error("Couldn't validate voucher code.");
            }
        },
        goBack() {
            this.$emit('back');
        }
    },
};
</script>
  
<style scoped>
input {
    transition: border-color 0.3s ease-in-out;
}

input:focus {
    outline: none;
    border-color: var(--color-secondary);
}

/* Additional styles to ensure equal width and centering of inputs */
input {
    /* Fixed width for each input */
    text-align: center;
}

.flex.space-x-2>input:not(:last-child) {
    margin-right: 0.5rem;
}
</style>
  