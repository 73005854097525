<template>
  <div class="flex flex-col items-start space-y-4 text-xs w-full">
    <h1 class="text-xl text-black font-inria text-left w-full mb-4">
      View Reference Material
    </h1>

    <div class="bg-white rounded shadow-sm border-b border-primary w-full">
      <div class="px-4 py-2 rounded-t-lg text-black w-full">
        <div class="flex justify-between items-center w-full">
          <h2 class="text-lg text-black">{{ materialTitle }}</h2>
          <CustomButton lowPadding hasIcon @button-click="goToFiles">
            <img :src="getIconPath('arrow-left-circle')" class="mx-auto w-5" />
          </CustomButton>
        </div>
      </div>

      <vue-pdf-app style="height: 100vh;" :config="config" :pdf="pdfUrl"></vue-pdf-app>
    </div>
  </div>
</template>
  
<script>
import CustomButton from '../CustomButton.vue';
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
import apiService from '../../services/apiService';

export default {
  name: 'ReferenceMaterialFile',
  components: {
    CustomButton,
    VuePdfApp
  },
  props: {
    id: String
  },
  data() {
    return {
      materialTitle: '',
      pdfUrl: '',
      zoom: 500,
      config: {
        toolbar: {
          toolbarViewerLeft: {
            previous: false,
            download: false,
          },
          toolbarViewerRight: false,
          toolbarViewerMiddle: {
            scaleSelectContainer: false,
          }
        },
          secondaryToolbar: false

      },
    };
  },
  async created() {
    await this.fetchMaterial();
  },
  methods: {
    async fetchMaterial() {
      // Replace with actual API call to fetch the reference material details
      const response = await apiService.getReferenceMaterial(this.id);
      if (response) {
        this.materialTitle = response.title;
        this.pdfUrl = response.s3_link; // Assuming the s3_link is the direct URL to the PDF file
      } else {
        this.$toast.error('Failed to fetch reference material.');
        // Redirect back to list or handle error appropriately
      }
    },
    goToFiles() {
      this.$router.push({ name: 'ReferenceMaterialOverview' });
    },
    getIconPath(iconName) {
      return require(`@/assets/${iconName}.svg`);
    }
  }
};
</script>
  