<template>
    <div class="flex flex-col items-start space-y-4 text-xs w-full">
        <h1 class="text-xl text-black font-inria text-left w-full mb-4">
            Select an examination criteria or create a new one
        </h1>
        <div v-if="evaluationCriteria.length === 0" class="text-primary text-sm">
            No evaluation criterias to show
        </div>
        <EvaluationCriteriaCard 
            v-for="criteria in evaluationCriteria" 
            :key="criteria.id" 
            :criteria="criteria"
            :isExpanded="expandedCriteria === criteria.id" 
            :isSelected="selectedCriteria === criteria.id"
            :isEditable="criteria.isEditable" 
            :isNew="criteria.isNew" 
            @toggle-details="toggleDetails"
            @select-card="selectCriteria" 
            @delete-criteria="deleteCriteria" 
            @save-criteria="saveCriteria"
            @save-new-criteria="saveNewCriteria" 
            @toggle-editable="toggleEditable"
            @request-validation="handleValidationRequest" />
        <CustomButton  
           isBorderedDotted 
            @button-click="createNewCriteria" 
            class="w-full items-center">
            <img :src="getIconPath('plus-circle')" class="mx-auto" />
        </CustomButton >
        <div class="flex justify-end w-full">
            <CustomButton @button-click="goToCreateExamination">Continue</CustomButton >
        </div>
    </div>
</template>

<script>
import EvaluationCriteriaCard from './EvaluationCriteriaCard.vue';
import apiService from '@/services/apiService';
import CustomButton  from '../CustomButton.vue';

const icons = require.context('@/assets', false, /\.svg$/);

export default {
    name: 'EvaluationCriteriaSelection',
    components: {
        EvaluationCriteriaCard,
        CustomButton 
    },
    props: {
        id: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            errorMessage: '',
            evaluationCriteria: [],
            expandedCriteria: null,
            selectedCriteria: null,
            needSelection: false,
            examination: null
        };
    },
    async created() {
        try {
            await this.fetchCriteria();
            if (this.id) {
                await this.preFillData();
            }
        } catch (error) {
            this.$toast.error( 'Error fetching evaluation criteria.');
        }
    },
    methods: {
        async fetchCriteria() {
            const response = await apiService.fetchEvaluationCriteria();
            if (response.authError || response.error == 'No access token available') {
                this.$toast.error( 'Session expired. Please log in again.');
                this.$router.push('/'); // Redirect to login
            } else if (response.error) {
                // Handle other errors
                this.$toast.error( response.error);
            } else {
                // Add isNew and isSaved properties to each criteria and only keep the ones that has show = true
                console.log(response)
                this.evaluationCriteria = response.map((criteria) => ({
                    ...criteria,
                    isNew: false,
                    isSaved: true
                })).filter((criteria) => criteria.show);
            }
        },
        async preFillData() {
            const examination = await apiService.getExamination(this.id);
            this.examination = examination;
            this.selectedCriteria = examination.evaluation_criteria.id;
        },
        getIconPath(iconName) {
            return icons(`./${iconName}.svg`);
        },
        toggleDetails(id) {
            this.expandedCriteria = this.expandedCriteria === id ? null : id;
        },
        selectCriteria(id) {
            this.selectedCriteria = this.selectedCriteria === id ? null : id;
        },
        goToCreateExamination() {
            if (this.selectedCriteria) {
                this.needSelection = false;
                console.log(this.selectedCriteria, this.evaluationCriteria)
                // Depending on if theres an examination id in the route params, we need to send it to the next page
                const params = this.id ? { examinationId: this.id, criteriaId: this.selectedCriteria } : { criteriaId: this.selectedCriteria };
                this.$router.push({ name: 'CreateExamination', params: params });
            } else {
                this.needSelection = true;
                this.$toast.error( 'Please select an evaluation criteria to continue.');
            }
        },
        toggleEditable(id) {
            const index = this.evaluationCriteria.findIndex(c => c.id === id);
            if (index !== -1) {
                this.evaluationCriteria[index].isEditable = !this.evaluationCriteria[index].isEditable;
            }
        },
        createNewCriteria() {
            this.evaluationCriteria.push({
                id: 0,
                name: '',
                description: '',
                evaluation_scales: [],
                isEditable: true,
                isNew: true,
                isExpanded: false,
                isSaved: false
            });
            this.expandedCriteria = 0;
        },
        deleteNewCriteria(id) {
            this.evaluationCriteria = this.evaluationCriteria.filter((criteria) => criteria.id !== id);
            this.expandedCriteria = null;
            this.selectedCriteria = null;
            this.needSelection = false;
            
        },
        async deleteCriteria(id) {
            if (id === 0) {
                this.deleteNewCriteria(id);
            } else {
                try {
                    const response = await apiService.hideEvaluationCriteria(id);
                    if (response.authError || response.error == 'No access token available') {
                    this.$toast.error( 'Session expired. Please log in again.');
                    this.$router.push('/'); // Redirect to login
                } else if (response.error) {
                    // Handle other errors
                    this.$toast.error( response.error);
                } else {
                        this.evaluationCriteria = await apiService.fetchEvaluationCriteria();
                    }
                } catch (error) {
                    this.$toast.error( 'An error occurred while deleting the criteria.');
                }
            }
        },
        handleValidationRequest(criteria, callback) {
            if (this.validateCriteria(criteria)) {
                callback();
            }
        },
        async saveCriteria(criteria) {
            // Validate criteria before attempting to save
            const isValid = this.validateCriteria(criteria);

            if (!isValid) {
                return;
            }

            try {
                if (criteria.isNew && !criteria.isSaved) {
                    await this.saveNewCriteria(criteria);
                } else {
                    const response = await apiService.updateEvaluationCriteria(criteria.id, criteria);
                    if (response.authError || response.error == 'No access token available') {
                    this.$toast.error( 'Session expired. Please log in again.');
                    this.$router.push('/'); // Redirect to login
                } else if (response.error) {
                    // Handle other errors
                    this.$toast.error( response.error);
                    this.updateEditable(criteria.id, false);
                } else {
                        this.evaluationCriteria = await apiService.fetchEvaluationCriteria();
                        this.updateEditable(criteria.id, false);
                    }
                }
                
            } catch (error) {
                this.$toast.error( 'An error occurred while saving the criteria.');
                console.error('Error saving criteria:', error);
            }
        },

        async saveNewCriteria(criteria) {
            const isValid = this.validateCriteria(criteria);

            if (!isValid) {
                // errorMessage is already set in validateCriteria
                return;
            }
            const response = await apiService.createEvaluationCriteria(criteria);
            if (response.authError || response.error == 'No access token available') {
                    this.$toast.error( 'Session expired. Please log in again.');
                    this.$router.push('/'); // Redirect to login
                } else if (response.error) {
                    // Handle other errors
                    this.$toast.error( response.error);
                } else {
                const index = this.evaluationCriteria.findIndex(c => c.id === 0);
                this.evaluationCriteria.splice(index, 1, {
                    ...response,
                    isEditable: false,
                    isNew: false,
                    isSaved: true,
                    isExpanded: false
                });
                this.updateEditable(criteria.id, false);
            }
        },
        validateCriteria(criteria) {
            if (!criteria.title || !criteria.description || criteria.evaluation_scales.length === 0) {
                this.$toast.error( 'Please fill in the title, description, and add at least one evaluation scale.');
                return false;
            }

            // Sort scales based on lower bound
            const sortedScales = criteria.evaluation_scales.sort((a, b) => a.scale_lower - b.scale_lower);

            // Check if scales cover the range from 0 to 100
            let totalScale = 0;
            let previousUpperBound = 0;

            for (const scale of sortedScales) {
                if (scale.scale_lower !== previousUpperBound) {
                    // There is a gap in the scale
                    this.$toast.error( `Gap detected in evaluation scales between ${previousUpperBound} and ${scale.scale_lower}.`);
                    return false;
                }
                if (scale.scale_upper <= scale.scale_lower) {
                    // Upper bound is not greater than lower bound
                    this.$toast.error( `Upper bound (${scale.scale_upper}) must be greater than lower bound (${scale.scale_lower}) in evaluation scales.`);
                    return false;
                }
                previousUpperBound = scale.scale_upper;
                totalScale += scale.scale_upper - scale.scale_lower;
            }

            if (totalScale !== 100) {
                this.$toast.error( 'Total range of evaluation scales must sum up to 100.');
                return false;
            }
            if (previousUpperBound !== 100) {
                this.$toast.error( `The upper bound of the last scale must be 100. Currently, it is ${previousUpperBound}.`);
                return false;
            }
            // All scales has names
            if (sortedScales.some(scale => !scale.name)) {
                this.$toast.error( 'Please fill in the name of all evaluation scales.');
                return false;
            }
            
            return true;
        },
        updateEditable(id, isEditable) {
            const index = this.evaluationCriteria.findIndex(c => c.id === id);
            if (index !== -1) {
                this.evaluationCriteria[index].isEditable = isEditable;
            } else {
                console.error('Criteria with id', id, 'not found');
            }
        },
        async loadExistingData(examinationId) {
            try {
                const response = await apiService.fetchExaminationById(examinationId);
                if (response.data) {
                    this.examination = response.data;
                    this.populateDataForEdit(response.data);
                }
            } catch (error) {
                this.$toast.error('Error loading data');
            }
        },
        populateDataForEdit(data) {
            this.selectedCriteria = data.evaluation_criteria.id;
        }
    },
};
</script>



<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>