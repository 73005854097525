<template>
    <div class="mx-auto p-4 text-black flex h-screen md:grid md:grid-cols-2 bg-white">
        <div class="bg-white w-full text-right mx-auto pl-16 m-auto">
            <h2 class="text-lg mb-6 font-raleway w-full text-right">TurkuEval</h2>
            <div class="space-y-6 ">
                <div class="mt-6 flex justify-between">
                    <span class="text-sm mr-8 text-primary">Name</span>
                    <span class="text-4xl font-inria">{{ examinationData.name }}</span>
                </div>

                <div v-if="examinationData.duration" class="mt-4 flex justify-between">
                    <span class="text-sm mr-8 text-primary">Duration</span>
                    <span class="text-xl font-inria">{{ formattedDuration }}</span>
                </div>
                <!-- If its both, show the combined message -->
                <div v-if="examinationData.access_material_before_test && examinationData.access_material_during_test"
                    class="text-sm text-right w-full">
                    Material access allowed before and during the test
                </div>
                <div v-else-if="examinationData.access_material_before_test" class="text-sm text-right w-full">
                    Material access allowed before the test
                </div>
                <div v-else-if="examinationData.access_material_during_test" class="text-sm text-right w-full">
                    Material access allowed during the test
                </div>

                <div class="flex justify-end space-x-3 text-xs pt-10">
                    <CustomButton isBordered hasBlackText hasIcon v-if="examinationData.access_material_before_test && !uuid"
                        @button-click="goToFiles(examinationData.reference_materials)">Access Materials</CustomButton>
                    <CustomButton @button-click="goToExam" :disabled="isSubmitted">Begin Examination</CustomButton>
                </div>
            </div>
        </div>
        <the-network-graph class="hidden md:block" />
    </div>
</template>
<script>
import CustomButton from '../CustomButton.vue';
import apiService from '../../services/apiService';
import TheNetworkGraph from '@/components/TheNetworkGraph.vue';

export default {
    name: 'StudentLanding',
    components: {
        TheNetworkGraph,
        CustomButton,
    },
    data() {
        return {
            examinationData: {},
            durationInHours: 0,
            durationInMinutes: 0,
            uuid: '',
            isSubmitted: false // Add isSubmitted flag
        };
    },
    async created() {
        if (localStorage.getItem('feedbackGiven')) {
            this.$router.push('/student-examinations/feedback');
        }
        await this.getPublicExaminationInformation();
        this.uuid = localStorage.getItem('uuid');
    },
    computed: {
        formattedDuration() {
            const hours = Math.floor(this.examinationData.duration / 3600);
            const minutes = Math.floor((this.examinationData.duration % 3600) / 60);
            return `${hours} hours, ${minutes} minutes`;
        },
    },
    methods: {
        async getPublicExaminationInformation() {
            const voucherId = localStorage.getItem('voucher');
            try {
                const response = await apiService.getPublicExaminationInformation(voucherId);
                this.examinationData = response;
                if (this.examinationData.duration) {
                    const durationInSeconds = this.examinationData.duration;
                    this.durationInHours = Math.floor(durationInSeconds / 3600);
                    this.durationInMinutes = Math.floor((durationInSeconds % 3600) / 60);
                }
            } catch (error) {
                this.$toast.error(error.response.data.message);
            }
        },
        goToFiles(materials) {
            console.log(materials); // Ensure this logs the expected array
            this.$router.push({
                name: 'StudentReferenceMaterials',
                params: { mode: "before" }
            });
        },
        async goToExam() {
            this.isSubmitted = true; // Disable button on click
            const voucherId = localStorage.getItem('voucher');
            try {
                if (localStorage.getItem('uuid')) {
                    this.$router.push({ name: 'StudentExamination' });
                    return;
                }
                const response = await apiService.postVoucher(voucherId);
                if (response.uuid) {
                    localStorage.setItem('uuid', response.uuid);
                    localStorage.setItem('studentCode', response.code);
                    console.log(response)
                    this.$router.push({ name: 'StudentExamination' });
                } else {
                    this.$toast.error('Error creating Student');
                }
            } catch (error) {
                this.$toast.error(error.response.data.message);
            } finally {
                this.isSubmitted = false; // Re-enable button after request completes
            }
        },
    }
};
</script>
