<template>
    <div class="mx-auto p-4 text-black">
        <h2 class="text-2xl mb-6"> {{ isEditMode ? 'Update' : 'Create' }} Examination </h2>
        <div class="space-y-4 font-inria">
            <div class="mb-4">
                <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
                <input v-model="formData.name" type="text" id="name" placeholder="Enter examination name"
                    class="mt-1 block w-full border-b border-primary rounded shadow-sm p-2 bg-white" />
            </div>

            <div class="flex space-x-5 justify-left w-full mb-4">
                <div class="w-3/5">
                    <label for="duration" class="block text-sm font-medium text-gray-700">Duration hs:ms</label>
                    <div class="flex items-center">
                        <input v-model="durationInHours" type="number" id="duration" placeholder="0"
                            class="mt-1 mr-2 block w-1/4 border-b border-primary rounded shadow-sm p-2 bg-white"
                            :disabled="noTimeLimit" />
                        <input v-model="durationInMinutes" type="number" id="duration" placeholder="0"
                            class="mt-1 mr-2 block w-1/4 border-b border-primary rounded shadow-sm p-2 bg-white"
                            :disabled="noTimeLimit" />
                        <input type="checkbox" id="no-limit" v-model="noTimeLimit"
                            class="mt-1 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded bg-white" />
                        <label for="no-limit" class="ml-2 block text-xs text-gray-900 w-1/4">No time limit</label>
                    </div>
                </div>
                <div class="w-2/5">
                    <label for="knowledge-level" class="block text-sm font-medium text-gray-700">Knowledge Level</label>
                    <select v-model="formData.knowledge_level" id="knowledge-level"
                        class="mt-1 block w-full border-b border-primary rounded shadow-sm p-2 bg-white">
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="advanced">Advanced</option>
                    </select>
                </div>
            </div>

            <div class="flex items-center">
                <input v-model="formData.access_material_before_test" id="before-test" type="checkbox"
                    class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-primary rounded">
                <label for="before-test" class="ml-2 block text-sm text-gray-900">Access Material Before Test</label>
            </div>
            <div class="flex items-center">
                <input v-model="formData.access_material_during_test" id="during-test" type="checkbox"
                    class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-primary rounded">
                <label for="during-test" class="ml-2 block text-sm text-gray-900">Access Material During Test</label>
            </div>

            <div class="mt-6">
                <h3 class="text-lg font-semibold mb-2">Evaluation Parameters</h3>
                <EvaluationParameterCard v-for="(param, index) in formData.evaluation_parameters" :key="index" :param="param"
                    :paramIndex="index" @update-param="updateEvaluationParameterCard" @delete-param="deleteParam" />
                <CustomButton  isBorderedDotted @button-click="addParam" class="w-full items-center">
                    <img :src="getIconPath('plus-circle')" class="mx-auto" />
                </CustomButton >
            </div>

            <div class="flex items-right justify-between">
                <div class="flex items-right">
                    <h1 v-if="errorMessage" class="text-sm text-primary font-inria text-left mb-4">
                        {{ errorMessage }}
                    </h1>
                </div>
                <div>
                    <!--The button adjusts to what it should do-->
                    <CustomButton  @button-click="submitForm" class="w-full items-center">
                        {{ isEditMode ? 'Update' : 'Create' }} Examination
                    </CustomButton >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EvaluationParameterCard from './EvaluationParameterCard.vue';
import CustomButton  from '../CustomButton.vue';
import apiService from '@/services/apiService';

export default {
    name: 'CreateExamination',
    components: {
        EvaluationParameterCard,
        CustomButton ,
    },
    props: {
        criteriaId: {
            type: String,
            default: null
        },
        examinationId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            formData: {
                name: '',
                date: '',
                duration: 45*60,
                knowledge_level: 'advanced',
                evaluation_criteria_id: null,
                access_material_before_test: true,
                access_material_during_test: false,
                evaluation_parameters: [
                    { name: 'Context Relevance', description: 'Assessing how appropriately and effectively the content relates to and aligns with the given context or scenario', impact: 'medium' },
                    { name: 'Factual Accuracy', description: "Evaluates the correctness of the information given in the student's answer.", impact: 'medium' },
                    { name: 'Completeness', description: 'Whether all required aspects, components, or elements of a subject or task have been thoroughly addressed', impact: 'medium' },
                    { name: 'Logical Consistency', description: 'Examines the coherence and logical flow of arguments, ensuring ideas or statements are rational and systematically aligned', impact: 'medium' },
                    { name: "Grammar and Spelling", description: "Grammar and Spelling are critical for clear communication. This criterion assesses the student's use of language, including the correct use of syntax, punctuation, and spelling. High performance in this category means the student's work is free of grammatical errors and spelling mistakes, making it easy to read and understand.", impact: 'medium' },
                ],
            },
            durationInMinutes: 45,
            durationInHours: '',
            noTimeLimit: false,
            errorMessage: '',
            isEditMode: false,
        };
    },
    created() {
        this.formData.date = new Date().toISOString().split('T')[0];
        if (this.examinationId) {
            this.isEditMode = true;
            this.loadExamination(this.examinationId);
        }
        this.formData.evaluation_criteria_id = this.criteriaId;
    },
    methods: {
        async loadExamination(examinationId) {
            try {
                const response = await apiService.getExamination(examinationId);
                if (response.authError || response.error) {
                    this.$toast.error(response.error || 'Error fetching examination.');
                } else {
                    console.log(response);
                    this.populateFormData(response);
                }
            } catch (error) {
                this.$toast.error('Error loading examination data.');
            }
        },
        populateFormData(examinationData) {
            this.formData = {
                ...examinationData,
            };
            if (this.formData.duration) {
                const durationInSeconds = this.formData.duration;
                this.durationInHours = Math.floor(durationInSeconds / 3600);
                this.durationInMinutes = Math.floor((durationInSeconds % 3600) / 60);
            }
            this.formData.evaluation_criteria_id = this.criteriaId;
        },
        async submitForm() {
            if (this.isValidForm()) {
                let response;
                this.updateDuration();
                if (this.isEditMode) {
                    response = await apiService.updateExamination(this.examinationId, this.formData);
                } else {
                    response = await apiService.createExamination(this.formData);
                }
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error( 'Session expired. Please log in again.');
                    this.$router.push('/');
                } else if (response.error) {
                    this.$toast.error( response.error);
                } else {
                    this.$router.push({ name: 'ReferenceMaterialSelection', params: { id: response.id } });
                }
            } else {
                this.$toast.error( 'Please fill all required fields before submitting.');
            }
        },
        isValidForm() {
            return this.formData.name && this.formData.evaluation_parameters.length > 0;
        },
        updateDuration() {
            this.formData.duration = (this.durationInHours * 3600) + (this.durationInMinutes * 60);
        },
        addParam() {
            if (this.formData.evaluation_parameters.length >= 6) {
                this.$toast.error( 'You can not add more than 6 parameters.');
                return;
            }
            this.formData.evaluation_parameters.push({ name: '', description: '', impact: 'medium', editableInitially: true, expandedInitially: true });
        },
        deleteParam(index) {
            this.formData.evaluation_parameters.splice(index, 1);
        },
        getIconPath(iconName) {
            return require(`@/assets/${iconName}.svg`);
        },
        updateEvaluationParameterCard(updatedParam, index) {
            this.formData.evaluation_parameters.splice(index, 1, updatedParam);
        },
    },
    watch: {
        noTimeLimit(newValue) {
            if (newValue) {
                this.durationInMinutes = '';
                this.durationInHours = '';
            }
        },
    },
};
</script>
