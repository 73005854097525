<template>
    <div class="container justify-left">
      <div class="h-1/3 mt-16 flex place-items-end w-1/2 mx-auto">
        <div>
          <h1 class="text-2xl text-primary font-inria text-left">
            Welcome to
          </h1>
          <h1 class="text-2xl font-inria text-left text-black mb-5">
            TurkuEval.AI
          </h1>
        </div>
      </div>
  
      <transition name="fade" mode="out-in">
        <div key="content">
          <div v-if="!educatorSelected && !studentSelected" class=" flex justify-center w-1/2 mx-auto">
            <div class="flex flex-col items-left space-y-4 text-xs w-full text-black">
              <h1 class="text-base">What is your role?</h1>
              <CustomButton hasIcon isBordered hasBlackText @button-click="onEducatorClick">
                I am an educator
              </CustomButton >
              <CustomButton  @button-click="onStudentClick">
                I am a student
              </CustomButton >
            </div>
          </div>
          <EducatorLogin v-if="educatorSelected" @back="resetSelection" />
          <StudentVoucherForm v-else-if="studentSelected" @back="resetSelection" />
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import CustomButton  from './CustomButton.vue';
  import EducatorLogin from './EducatorLogin.vue';
  import StudentVoucherForm from './StudentVoucherForm.vue';
  
  export default {
    name: 'RoleSelection',
    components: {
      CustomButton ,
      EducatorLogin,
      StudentVoucherForm,
    },
    data() {
      return {
        educatorSelected: false,
        studentSelected: false,
      };
    },
    methods: {
      onEducatorClick() {
        this.educatorSelected = true;
      },
      onStudentClick() {
        this.studentSelected = true;
      },
      resetSelection() {
        this.educatorSelected = false;
        this.studentSelected = false;
      }
    },
  };
  </script>
  
  <style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  