<template>
    <div class="bg flex justify-center w-1/2 mx-auto py-4 rounded text-sm">
        <form @submit.prevent="" class="flex flex-col space-y-4 w-full">
            <input class="py-2 px-4 rounded text-black bg-white border border-primary" type="text"
                v-model="formData.username" placeholder="Username" />
            <input class="py-2 px-4 rounded text-black bg-white border border-primary" type="password"
                v-model="formData.password" placeholder="Password" />
            <CustomButton @button-click="onSubmit">
                Submit
            </CustomButton >
            <CustomButton  isLinkStyle @button-click="goToEducatorCreate">
                Ask for an account
            </CustomButton >
            <CustomButton  isLinkStyle @button-click="goBack">
                Back
            </CustomButton >
        </form>
    </div>
</template>
  
<script>
import CustomButton  from './CustomButton.vue';
import apiService from '@/services/apiService'; // Adjust path as needed


export default {
    name: 'EducatorLogin',
    components: {
        CustomButton ,
    },
    data() {
        return {
            formData: {
                username: '',
                password: '',
            },
        };
    },
    methods: {

        async onSubmit() {
            try {
                const response = await apiService.login(this.formData.username, this.formData.password);
                if (response.authError || response.error == 'No access token available') {
                    this.$toast.error('Incorrect username or password.');
                    this.$router.push('/');
                } else if (response.error) {
                    this.$toast.error(response.error);
                } else {
                    this.$toast.success('Login successful.');
                    this.$router.push('/examinations');
                }
            } catch (error) {
                this.$toast.error('Incorrect username or password.');
            }   
        },
        goToEducatorCreate() {
            const mailtoLink = `mailto:agustin.garagorryguerra@utu.fi?subject=Request for Educator Account&body=I would like to request an account as an educator.`;
            window.location.href = mailtoLink;
        },
        goBack() {
            this.$emit('back');
        }
    },
};
</script>
  
<style scoped>
input {
    transition: border-color 0.3s ease-in-out;
}

input:focus {
    outline: none;
    border-color: var(--color-secondary);
}
</style>
  