<template>
    <div>Resetting...</div>
</template>
<script>
export default {
  name: 'ResetEvaluation',
  created() {
        localStorage.removeItem('voucher');
        localStorage.removeItem('student_uuid');
        localStorage.removeItem('feedbackGiven');
        localStorage.removeItem('studentCode');
        localStorage.removeItem('uuid');
        localStorage.removeItem('experience_feedback');
        this.$router.push('/');
        
    },
};
</script>