<template>
    <button 
      :class="buttonClasses"
    @click.stop="onButtonClick"
    >
      <slot></slot>
    </button>
  </template>
  
  <script>
  export default {
    name: 'CustomButton ',
    props: {
      isBordered: {
        type: Boolean,
        default: false,
      },
      isBorderedDotted: {
        type: Boolean,
        default: false,
      },
      isLinkStyle: {
        type: Boolean,
        default: false,
      },
      lowPadding: {
        type: Boolean,
        default: false,
      },
      hasBlackText: {
        type: Boolean,
        default: false,
      },
hasIcon: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      buttonClasses() {
        if (this.isLinkStyle) {
          return [
            'underline text-primary hover:text-secondary',
            'transition duration-300 ease-in-out',
          ];
        } else {
          return [
            'py-2 transition duration-300 ease-in-out transform hover:shadow-md hover:scale-95 font-inria',
            this.isBordered ? 'border-primary border' : '', 
            this.isBorderedDotted ? 'border-primary border-b bg-white rounded' : 'rounded',
            this.lowPadding ? 'px-2' : 'px-4',
            this.hasBlackText ? 'text-black' : 'text-white',
            this.hasIcon? '' : 'bg-primary',
          ];
        }
      },
    },
    methods: {
    onButtonClick(event) {
      this.$emit('button-click', event);
    },
    },
  };
  </script>
  
  <style scoped>
  /* Add additional styling if needed */
  </style>
  