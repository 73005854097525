<template>
    <div class="p-4 border-b border-primary rounded mb-4  bg-white">
        <div class="flex justify-between items-center w-full">
            <div class="flex">
                <h3 class="text-base font-semibold">{{ localParam.name }}</h3>
                <h3 class="text-sm items-center flex ml-2 capitalize"> {{ localParam.impact }} impact</h3>
            </div>
            <div class="flex">
                <CustomButton hasIcon lowPadding @button-click="deleteParam" class="mr-2">
                    <img :src="getIconPath('trash-2')" class="mx-auto w-5" />
                </CustomButton >
                <CustomButton hasIcon lowPadding @button-click="isEditable ? saveChanges() : toggleEdit()" class="mr-2">
                    <img :src="getIconPath(isEditable ? 'save' : 'file-edit')" class="mx-auto w-5" />
                </CustomButton >
                <CustomButton hasIcon lowPadding @button-click="toggleExpand" class="mr-2">
                    <img :src="getIconPath(isExpanded ? 'chevron-up' : 'chevron-down')" class="mx-auto w-5" />
                </CustomButton >
            </div>
        </div>

        <div v-show="isExpanded">
            <div class="w-full">
                <label for="name" class="block text-sm font-medium text-black my-1">Parameter Name</label>
                <input v-model="localParam.name" :readonly="!isEditable" :class="inputClass" placeholder="Parameter Name"
                    type="text" />
            </div>
            <div class="w-full">
                <label for="description" class="block text-sm font-medium text-black my-1">Description</label>
                <textarea v-model="localParam.description" @input="adjustTextareaHeight" :readonly="!isEditable"
                    :class="textareaClass" placeholder="Description" rows="2"></textarea>
            </div>
            <label for="impact" class="block text-sm font-medium text-black my-1 w-full">Impact</label>
            <div class="w-full flex">
                <div class="w-2/3 mr-4">
                    <select v-model="localParam.impact" :disabled="!isEditable" :class="inputClass">
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton  from '../CustomButton.vue';

const icons = require.context('@/assets', false, /\.svg$/);

export default {
    name: 'EvaluationParameterCard',
    components: {
        CustomButton ,
    },
    props: {
        param: Object,
        paramIndex: Number,
    },
    data() {
        return {
            localParam: { ...this.param },
            isExpanded: this.param.expandedInitially,
            isEditable: this.param.editableInitially,
            editableParam: { ...this.param },
        };
    },
    computed: {
        inputClass() {
            return {
                'block w-full rounded p-2 border-b bg-white': true,
                'border-primary': this.isEditable,
                'border-transparent cursor-default': !this.isEditable,
                'focus:outline-none': true,
            };
        },
        textareaClass() {
            return {
                'block w-full rounded-md resize-none p-2 border-b bg-white': true,
                'border-primary': this.isEditable,
                'border-transparent cursor-default': !this.isEditable,
                'focus:outline-none': true,
            };
        },
    },
    methods: {
        adjustTextareaHeight(event) {
            event.target.style.height = 'auto'; // Reset height to calculate new height
            event.target.style.height = event.target.scrollHeight + 'px'; // Set new height
        },
        toggleExpand(event) {
            console.log('Event:', event);
            this.isExpanded = !this.isExpanded;
            console.log('isExpanded:', this.isExpanded);
        },
        toggleEdit() {
            this.isEditable = true;
            this.editableParam = { ...this.param }; // Reset to original data when starting to edit
        },
        saveChanges() {
            if (this.validateParam()) {
                this.isEditable = false;
                this.$emit('update-param', this.localParam, this.paramIndex); // Emit the updated parameter and its index
            }
            else {
                this.$toast.error('Please fill all the fields');
                this.localParam = { ...this.editableParam }; // Reset to original data if validation fails
            }
        },
        deleteParam() {
            this.$emit('delete-param', this.paramIndex);
        },
        getIconPath(iconName) {
            return icons(`./${iconName}.svg`);
        },
        validateParam() {
            if (this.localParam.name === '') {
                return false;
            }
            if (this.localParam.description === '') {
                return false;
            }
            if (this.localParam.impact === '') {
                return false;
            }
            return true;
        }
    },
    watch: {
        param: {
            deep: true,
            handler(newVal) {
                this.localParam = { ...newVal };
            }
        },

    }
};
</script>
