<template>
  <div class="px-4">
    <a :href="link" class="my-4 flex items-center text-sm px-4 py-2 cursor-pointer rounded transition duration-300 ease-in-out transform hover:bg-primary-dark">
      <img :src="icon" :alt="altText" class="h-5 w-5 mr-2" />
      <slot></slot>
    </a>
  </div>
</template>
  
  <script>
  export default {
    props: {
      link: String,
      icon: String,
      altText: String
    }
  }
  </script>
  