<template>
    <div class="flex flex-col items-center justify-center w-full p-4 text-black bg-gray-100">
      <h2 class="text-2xl font-inria text-center text-primary mb-6">TurkuEval Questionnaire</h2>
      <div class="flex flex-col items-center w-full md:w-2/3 mb-4 bg-white p-6 rounded shadow">
      <h3 class="text-sm font-inria text-left text-black mb-6">The TurkuEval questionnaire is designed to have a deper understanding of the student perception about AI implementation in the educational field. All of the questions are optional. Data will be anonymous and will only be used for research purposes. </h3>
  
        <!-- Year Field -->
        <div class="input-group mb-4">
          <label for="year" class="input-label">Year of Birth</label>
          <input type="number" id="year" v-model.number="feedbackData.year"
            class="input-field"
            placeholder="Enter year..." min="1980" max="2020" />
        </div>
  
        <!-- Gender Field -->
        <div class="input-group mb-4">
          <label for="gender" class="input-label">What is your gender?</label>
          <select id="gender" v-model="feedbackData.gender" class="input-field">
            <option disabled value="">Select gender</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="other">Other</option>
          </select>
        </div>
  
        <!-- AI Familiarity Field -->
        <div class="input-group mb-4">
          <label for="ai_familiarity" class="input-label">Describe your level of familiarity with AI</label>
          <select id="ai_familiarity" v-model="feedbackData.ai_familiarity" class="input-field">
            <option disabled value="">Select your familiarity level</option>
            <option value="beginner">Beginner</option>
            <option value="basic">Basic</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
            <option value="expert">Expert</option>
          </select>
        </div>
  
        <!-- Agreement Level Fields -->
        <template v-for="(item, index) in agreementItems" :key="index">
          <div class="input-group mb-4">
            <label class="input-label">{{ item.label }}</label>
            <div class="flex flex-wrap mt-2">
              <label class="radio-label" v-for="(option, oIndex) in agreementOptions" :key="oIndex">
                <input type="radio" :value="option.value" :name="item.field" v-model="feedbackData[item.field]" class="radio-input">
                {{ option.text }}
              </label>
            </div>
          </div>
        </template>
  
        <!-- Free Field -->
        <div class="input-group mb-4">
          <label for="ai_risks_in_education" class="input-label">What concerns or risks do you associate with the use of AI in education?</label>
          <textarea id="ai_risks_in_education" v-model="feedbackData.ai_risks_in_education" rows="4"
            class="input-field"
            placeholder="Share your thoughts..."></textarea>
        </div>
  
        <!-- Submit Button -->
        <CustomButton @click="submitFeedback" class="submit-button text-xs">
          Submit Feedback
        </CustomButton>
      </div>
    </div>
  </template>
  

  <script>
  import CustomButton from '../CustomButton.vue';
import apiService from '../../services/apiService';

  export default {
    name: 'StudentExperienceFeedbackForm',
components: {
      CustomButton,
    },
    data() {
      return {
        feedbackData: {
          year: null,
          gender: '',
          ai_familiarity: '',
          ai_accuracy: '',
          ai_trust: '',
          ai_feedback_constructive: '',
          ai_evaluation_as_good_as_human: '',
          ai_evaluation_confortable: '',
          ai_risks_in_education: ''
        },
        agreementItems: [
          { field: 'ai_accuracy', label: 'Chat-GPT provides accurate evaluations of my answers' },
          { field: 'ai_trust', label: "I trust the Chat-GPT model's assessment of my work" },
          { field: 'ai_feedback_constructive', label: 'Chat-GPT offers constructive feedback' },
          { field: 'ai_evaluation_as_good_as_human', label: 'Chat-GPT based evaluation is as good as human evaluation' },
          { field: 'ai_evaluation_confortable', label: 'I feel comfortable with a machine evaluating my academic work' }
        ],
        agreementOptions: [
          { value: 'strongly_disagree', text: 'Strongly Disagree' },
          { value: 'disagree', text: 'Disagree' },
          { value: 'neutral', text: 'Neutral' },
          { value: 'agree', text: 'Agree' },
          { value: 'strongly_agree', text: 'Strongly Agree' }
        ]
      };
    },
    created() {
      const experience_feedback = localStorage.getItem('experience_feedback');
      if (experience_feedback) {
        this.$router.push('/student-examinations/feedback');
      }
    },
    methods: {
      async submitFeedback() {
        try {
            // error if uuid is not found
            const student_uuid = localStorage.getItem('uuid');
            if (!student_uuid) {
                this.$toast.error("Error submitting feedback, you need to take an examination first");
                return;
            }
          if(this.validateFeedbackDataIsCorrect()){
            const response = await apiService.submitFeedback(student_uuid, this.feedbackData);
            this.$toast.success('Feedback submitted successfully. Thank you so much for your time!');
            console.log(response)
            localStorage.setItem('experience_feedback', 'true')
            this.$router.push('/');
          } else {
            this.$toast.error('Please any of the fields.');
          }
        } catch (error) {
          console.error('Error submitting feedback:', error);
          this.$toast.error('Error submitting feedback.');
        }
      },
      validateFeedbackDataIsCorrect() {
      let isValid = false;

      // Validate Year - it should be within the range if filled
      if (this.feedbackData.year && (this.feedbackData.year < 1920 || this.feedbackData.year > 2020)) {
        this.$toast.error('Year of birth must be between 1920 and 2020.');
        return false;
      }

      // Check if at least one other field is filled out
      if (this.feedbackData.gender ||
          this.feedbackData.ai_familiarity ||
          this.feedbackData.ai_accuracy ||
          this.feedbackData.ai_trust ||
          this.feedbackData.ai_feedback_constructive ||
          this.feedbackData.ai_evaluation_as_good_as_human ||
          this.feedbackData.ai_evaluation_confortable ||
          this.feedbackData.ai_risks_in_education) {
        isValid = true;
      }

      // If no fields are filled, show an error
      if (!isValid) {
        this.$toast.error('Please fill out at least one field.');
      }

      return isValid;
    },
    }
  };
  </script>
  
  <style scoped>
  .input-group {
    width: 100%;
  }
  
  .input-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .input-field, .radio-label {
    display: block;
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.25rem;
    transition: border-color 0.3s, background-color 0.3s;
  }

  .radio-label {
    margin-right: 1rem;
    cursor: pointer;
  }
  
  .radio-input {
    margin-right: 0.25rem;
  }
  </style>
  