<template>
    <div class="flex flex-col w-full min-h-screen bg-white">
        <div v-if="!examinationData" class="rotate-graph absolute inset-0 bg-network-pattern bg-cover md:w-screen w-96"></div>    
        <div v-if="examinationData" class="w-full md:w-2/3 mx-auto p-4 bg-white">
            <p class="p-4 text-white rounded text-lg bg-primary text-center">
                Please, read your feedback carefully and proceed to the user survey at the end
            </p>
            <exam-results 
                :questions-data="examinationData.questions_data"
                :total-mark="examinationData.total_mark"
                :mark-converted="examinationData.grade"
            ></exam-results>
            <div class="flex justify-center items-center w-full">
                <LargeSelectionButton  v-if="!feedbackGiven" :title="'Click here to continue to user survey'" @click="goToSurvey" />
            </div>            
        </div>

        <div v-else-if="isLoading" class="flex flex-col justify-center items-center h-screen">
            <h1 class="text-black text-xl">AI is evaluating the results...</h1>
            <h1 class="text-black text-xs mb-4">This might take a few moments, please don’t close this window</h1>

        </div>

        <div v-else class="flex justify-center items-center h-screen text-black">
            <span>Examination data not available</span>
        </div>
    </div>
</template>


<script>
import ExamResults from '@/components/StudentExamination/FeedbackCard.vue';
import apiService from '@/services/apiService';
import LargeSelectionButton from '../LargeSelectionButton.vue';

export default {
    name: 'FeedbackVisualization',
    components: {
        ExamResults,
        LargeSelectionButton,
    },
    data() {
        return {
            examinationName: '',
            examinationData: null,
            isLoading: true,
            feedbackGiven: false
        };
    },
    created() {
        localStorage.setItem('feedbackGiven', true);
        this.fetchExaminationResults();
        this.feedbackGiven = localStorage.getItem('experience_feedback')
    },
    methods: {
        async fetchExaminationResults() {
            try {
                console.log("fetching examination results");
                const uuid = localStorage.getItem('uuid');
                const response = await apiService.endExamination(uuid);
                if (response.authError) {
                    this.$toast.error('Session expired. Please log in again.');
                    this.$router.push('/'); 
                } else if (response.error) {
                    this.$toast.error("We are having some problems with the AI evaluation, your examination has been sent to be evaluated manually by the teacher. Sorry for the inconvenience.");
                    this.$router.push('/');
                } else {
                    this.examinationData = response;
                    console.log(response);
                    this.examinationName = "Your Examination Results";
                    localStorage.setItem('feedbackGiven', true);
                }
                
            } catch (error) {
                this.$toast.error("We are having some problems with the AI evaluation, your examination has been sent to be evaluated manually by the teacher. Sorry for the inconvenience.");
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        goToSurvey(){
            this.$router.push({ name: 'StudentExperienceFeedbackForm' })
        }
    },
};
</script>

<style scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-graph {
  animation: rotate 120s linear infinite;
  background-image: url('@/assets/mainpagedecorator.svg');
  width: 50vw; /* Adjust viewport width percentage as needed */
  height: 100vh; /* Adjust viewport height percentage as needed */
  background-size: 100% 100%; 
}

/* Additional styles... */
</style>