<template>
    <div>
        <!-- Toggle button for mobile view (only shown when menu is not open) -->
        <button v-if="!isOpen" @click="toggle" class="md:hidden z-40 absolute top-0 left-0 mt-4 ml-4">
            <img src="@/assets/menu.svg" alt="Menu" class="h-6 w-6" />
        </button>

        <!-- Side menu content -->
        <aside :class="{ 'transform-none': isOpen, '-translate-x-full': !isOpen }"
            class="fixed inset-y-0 left-0 w-64 bg-black text-white transition-transform duration-300 ease-in-out z-30 md:transform-none">
            <!-- Close button for mobile view (only shown when menu is open) -->
            <button v-if="isOpen" @click="toggle" class="md:hidden z-40 absolute top-0 right-0 mt-4 mr-4">
                <img src="@/assets/close.svg" alt="Close" class="h-6 w-6" />
            </button>

            <!-- Logo Text -->
            <div class="flex flex-col justify-between h-screen">
            <nav class="mt-4">
            <div class="font-raleway text-white font-medium p-4 tracking-wider text-xl">
                TurkuEval.AI
            </div>
                <!-- Navigation items with icons -->
                <MenuButton @click="goToExaminations" :icon="getIconPath('book-check')" altText="Examinations">
                    Examinations
                </MenuButton>
                <MenuButton @click="goToFiles" :icon="getIconPath('file-text')" altText="Files">
                    Files
                </MenuButton>
                <!-- Add other menu items here -->
            </nav>
            <nav class="mt-4">
                <MenuButton @click="logout" :icon="getIconPath('log-out')" altText="Logout">
                    Logout
                </MenuButton>
            </nav></div>
        </aside>

        <!-- Overlay for mobile view -->
        <div class="fixed inset-0 bg-black opacity-50 z-20" @click="toggle" v-if="isOpen" />
    </div>
</template>
  
<script>
import MenuButton from './MenuButton.vue'
const icons = require.context('@/assets', false, /\.svg$/);

export default {
    name: 'EducatorMainMenu',
    components: {
        MenuButton,
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
        getIconPath(iconName) {
            return icons(`./${iconName}.svg`);
        },
        goToExaminations() {
            this.$router.push('/examinations');
        },
        goToFiles() {
            this.$router.push({ name: 'ReferenceMaterialOverview'});
        },
        logout(){
            localStorage.removeItem('access_token');
            this.$router.push('/');
        }
    },
};
</script>
    
<style scoped>
@media (min-width: 768px) {
    .md\:hidden {
        display: none !important;
    }

    .md\:block {
        display: block !important;
    }

    .md\:transform-none {
        transform: none !important;
    }
}

/* Tailwind CSS does not include a 'transform-none' utility class by default */
.transform-none {
    transform: none;
}

/* Hide menu by translating it off screen */
.-translate-x-full {
    transform: translateX(-100%);
}
</style>
